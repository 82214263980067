import { useState } from "react";
import { getConversationMessages, getConversations } from "../../Services/Chat";
import { socket } from "../../Utils/socket";
import { useQuery } from "react-query";
import ChatList from "./List";
import ChatMessage from "./Message";
import "./style.css";

export default function Chat() {
  const [currentTab, setCurrentTab] = useState("users");
  // const [chatTab, setChatTab] = useState("chat-1");
  const [chatTab, setChatTab] = useState("");
  const [chat, setChat] = useState([]);
  const [messagesEndRef, setMessagesEnfRef] = useState(null);

  const handleTabClick = async (tabId) => {
    setChatTab(tabId);
    socket.emit("join-chat-room", tabId);
    // if (messagesEndRef?.current) {
    //   setTimeout(() => messagesEndRef?.current?.scrollIntoView(), 500);
    // }
  };

  const {
    isFetching: conversationFetching,
    isLoading: conversationLoading,
    data: conversationData,
  } = useQuery(["get-conversations", currentTab], () =>
    getConversations(
      currentTab === "users" ? "user" : currentTab === "movers" && "driver"
    )
  );
  // console.log("get-conversations Data: ", conversationData?.data);

  const { isFetching: chatFetching, isLoading: chatLoading } = useQuery(
    ["get-conversation-messages", chatTab],
    () => getConversationMessages(chatTab),
    {
      onSuccess: (res) => setChat(res?.data?.data?.reverse()),
      onError: (err) => console.log("Error: ", err?.response?.data),
    }
  );

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <h1 className="pageTitle text-capitalize m-0">Chat</h1>
                    </div>
                  </div>
                  <section className="chat-page">
                    <div className="row">
                      <div className="col-md-6 col-lg-5 col-xl-3 mb-2 mb-md-0">
                        <aside id="chat-sidebar" className="admin-chat">
                          <div className="nav nav-tabs" role="tablist">
                            <div
                              className={`nav-item ${currentTab === "users" ? "active" : ""
                                }`}
                            >
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                role="tab"
                                onClick={() => {
                                  setCurrentTab("users");
                                }}
                              >
                                Users
                              </a>
                            </div>
                            <div
                              className={`nav-item ${currentTab === "movers" ? "active" : ""
                                }`}
                            >
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                role="tab"
                                onClick={() => {
                                  setCurrentTab("movers");
                                }}
                              >
                                Muvrrs
                              </a>
                            </div>
                          </div>
                          <div className="tab-content">
                            <div
                              className={`tab-pane ${currentTab === "users" ? "active" : ""
                                }`}
                              id="users"
                              role="tabpanel"
                            >
                              <ul>
                                {conversationLoading || conversationFetching ? (
                                  <div style={{ textAlign: "center" }}>
                                    <i
                                      className="fas fa-circle-notch fa-spin"
                                      style={{
                                        fontSize: 42,
                                      }}
                                    />
                                  </div>
                                ) : conversationData?.data?.data?.length > 0 ? (
                                  conversationData?.data?.data?.map(
                                    (item, index) => (
                                      <ChatList
                                        key={`list-${item._id}`}
                                        item={item}
                                        chatTab={chatTab}
                                        handleTabClick={handleTabClick}
                                      />
                                    )
                                  )
                                ) : (
                                  <div style={{ textAlign: "center" }}>
                                    No Users Found
                                  </div>
                                )}
                              </ul>
                            </div>
                            <div
                              className={`tab-pane ${currentTab === "movers" ? "active" : ""
                                }`}
                              id="movers"
                              role="tabpanel"
                            >
                              <ul>
                                {conversationLoading || conversationFetching ? (
                                  <div style={{ textAlign: "center" }}>
                                    <i
                                      className="fas fa-circle-notch fa-spin"
                                      style={{
                                        fontSize: 42,
                                      }}
                                    />
                                  </div>
                                ) : conversationData?.data?.data?.length > 0 ? (
                                  conversationData?.data?.data?.map(
                                    (item, index) => (
                                      <ChatList
                                        key={`list-${item._id}`}
                                        item={item}
                                        chatTab={chatTab}
                                        handleTabClick={handleTabClick}
                                      />
                                    )
                                  )
                                ) : (
                                  <div style={{ textAlign: "center" }}>
                                    No Users Found
                                  </div>
                                )}
                              </ul>
                            </div>
                          </div>
                        </aside>
                      </div>
                      <div className="col-md-6 col-lg-7 col-xl-9">
                        <div className="tab-content">
                          <div className={`tab-pane active`}>
                            <ChatMessage
                              key={"chat-messages"}
                              data={chat}
                              dataLoading={chatLoading}
                              dataFetching={chatFetching}
                              setChat={setChat}
                              setMessagesEnfRef={setMessagesEnfRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
