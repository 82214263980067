import { Link, useNavigate, useParams } from "react-router-dom";
import { Confirmation, Error, Reason, Success } from "../../Components/Modal";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  changeVehicleRequestStatus,
  getVehicleRequestDetails,
} from "../../Services/VehicleRequest";
import { format_date } from "../../Utils/helpers";
import Button from "../../Components/Button";

export default function VehicleRequestDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [requestDetail, setRequestDetail] = useState({});

  const { data, isLoading, refetch } = useQuery(
    ["vehicleRequest_details", id],
    () => getVehicleRequestDetails(id)
  );
  // console.log("vehicleRequest_details: ", data?.data);

  useEffect(() => {
    if (data?.data?.data?.docs?.length > 0)
      setRequestDetail(data?.data?.data?.docs?.[0]);
  }, [data?.data?.data?.docs]);

  const { mutate, isLoading: loadingStatus } = useMutation(
    (data) => changeVehicleRequestStatus(id, data),
    {
      onSuccess: (res) => {
        refetch();
        Success("Vehicle Request Status", res.data?.message);
      },
      onError: (err) => {
        Error(err?.response?.data?.message);
      },
    }
  );
  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0">
                            Muvrrs Management
                          </h1>
                          <span className="mx-2">
                            <button
                              type="button"
                              className="backLink mr-1"
                              onClick={() => navigate(-1)}
                            >
                              <i className="fas fa-chevron-left" />
                            </button>
                            Vehicle Request
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-xxl-8">
                      <div className="row dashCard2 py-3 px-4">
                        <div className="col-12">
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Muvrrs Full Name:
                            </label>
                            <p className="mainText">
                              {requestDetail?.user?.firstName}
                            </p>
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Vehicle Make:
                            </label>
                            <p className="mainText">{requestDetail?.brandName}</p>
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Vehicle Name:
                            </label>
                            <p className="mainText">{requestDetail?.name}</p>
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Vehicle Type:
                            </label>
                            <p className="mainText">
                              {requestDetail?.type?.name}
                            </p>
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Vehicle Year:
                            </label>
                            <p className="mainText">
                              {requestDetail?.year}
                            </p>
                          </div>
                          {/* <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Vehicle Number:
                            </label>
                            <p className="mainText">
                              {requestDetail?.numberPlate}
                            </p>
                          </div> */}
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Vehicle Color:
                            </label>
                            <p className="mainText">{requestDetail?.color}</p>
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Plate No:
                            </label>
                            <p className="mainText">
                              {requestDetail?.numberPlate}
                            </p>
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Request Date:
                            </label>
                            <p className="mainText">
                              {requestDetail?.createdAt &&
                                format_date(
                                  requestDetail?.createdAt,
                                  "DD/MM/YYYY"
                                )}
                            </p>
                          </div>
                          <div className="mainDetail d-flex align-items-baseline flex-wrap my-1">
                            {
                              requestDetail?.vehicleImage?.map(item => (
                                <img
                                  src={
                                    item?.imageUrl ??
                                    "images/truck1.png"
                                  }
                                  alt=""
                                  className="mainImage"
                                />
                              ))
                            }
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel" />
                            <div className="actions">
                              <Link
                                to="/mover-management/vehicle-requests"
                                className="siteBtn2 mt-1"
                              >
                                Vehicle Requests
                              </Link>
                              {requestDetail?.status == "pending" && (
                                <>
                                  <Button
                                    type="button"
                                    className="siteBtn ms-1 mt-1"
                                    loading={loadingStatus}
                                    onClick={() => {
                                      Confirmation(
                                        `Are You Sure You Want To Approve The Vehicle Request ?`,
                                        "Yes",
                                        () => mutate({ status: "approved" })
                                      );
                                    }}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#activeVehicleRequest"
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    type="button"
                                    className="siteBtn ms-1 mt-1"
                                    loading={loadingStatus}
                                    onClick={() => {
                                      Reason(
                                        "Are you sure you want to reject the vehicle request ?",
                                        "Done",
                                        (val) =>
                                          mutate({
                                            status: "rejected",
                                            reason: val,
                                          }),
                                        "Please enter your rejection reason"
                                      );
                                    }}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#activeVehicleRequest"
                                  >
                                    Reject
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="modal fade"
              id="activeVehicleRequest"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/question.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Are you sure you want to approve the vehicle request
                            ?
                          </p>
                        </div>
                      </div>
                      <div className="modalAction">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="siteBtn2 mx-1"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          data-bs-toggle="modal"
                          data-bs-target="#activatedVehicleRequest"
                          className="siteBtn mx-1"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="activatedVehicleRequest"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/check.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Vehicle request has been approved successfully
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="inactiveVehicleRequest"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/question.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Are you sure you want to reject the vehicle request
                            ?
                          </p>
                        </div>
                      </div>
                      <form action="">
                        <div className="modalForm">
                          <div className="text-center">
                            <h3 className="sectionTitle">Reject Reason</h3>
                          </div>
                          <textarea
                            name=""
                            id=""
                            rows={4}
                            className="modalInput"
                            defaultValue={""}
                          />
                        </div>
                        <div className="modalAction my-3">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="siteBtn2 mx-1"
                          >
                            No
                          </button>
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#inactivatedVehicleRequest"
                            className="siteBtn mx-1"
                          >
                            Yes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="inactivatedVehicleRequest"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/times.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Vehicle request has been rejected
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>
    </div>
  );
}
