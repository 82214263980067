import Button from "../../Components/Button";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { editProfileSchema } from "../../Schema/profileSchema";
import useFileReader from "../../Hooks/useFileReader";
import { updateProfile } from "../../Services/Profile";
import { setUser } from "../../Redux/slice";
import { ImageWrapper } from "../../Services/General";
import { Error } from "../../Components/Modal";

export default function EditProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getThumbnail } = useFileReader();
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setImage] = useState("");
  const { user } = useSelector((state) => state?.slice);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(editProfileSchema),
  });

  useEffect(() => {
    if (user?.profileImage) {
      setSelectedFile(user?.profileImage?.imageUrl);
    }

    reset({
      name: user?.firstName,
    });
  }, [user]);

  const { mutate, isLoading } = useMutation((data) => updateProfile(data), {
    retry: false,
    onSuccess: (res) => {
      // console.log("updateProfile Response: ", res?.data);
      dispatch(setUser(res.data?.user));
      navigate("/profile");
    },
    onError: (err) => {
      // console.log("updateProfile Err: ", err);
      Error(err.response.data?.message);
    },
  });

  const handleUploadImage = async (e) => {
    let file = e.target?.files?.[0];
    // console.log("handleUploadImage", file)
    if (file) {
      let fileThumbnail = await getThumbnail(file);
      setSelectedFile(fileThumbnail);
      setImage(file);
    }
  };

  const submit = (data) => {
    // console.log({ data });

    const apiformData = new FormData();
    apiformData.append("image", image);

    ImageWrapper(
      user?._id,
      apiformData,
      user?.profileImage?.imageUrl,
      (image) => {
        mutate({
          firstName: data?.name,
          profileImage: image?._id,
        });
      }
    );
  };

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-center">
                          <Link to="/profile" className="forgotLink pe-3">
                            <i class="fas fa-arrow-left"></i>
                          </Link>
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                            Profile
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-xxl-6 mb-4">
                      <div className="dashCard2 py-3 px-4">
                        <form
                          //  action="profile.php"
                          onSubmit={handleSubmit(submit)}
                        >
                          <div className="mainDetail text-center my-3">
                            <div className="userIimageFrame mx-auto my-3">
                              <img
                                src={selectedFile ?? "images/avatar.png"}
                                alt=""
                                className="userImage img-fluid"
                              />
                              <label
                                htmlFor="userImage"
                                className="uploadCamera"
                              >
                                <i className="fas fa-camera" />
                              </label>
                              <input
                                type="file"
                                name=""
                                id="userImage"
                                accept="image/*"
                                className="d-none"
                                onChange={(e) => handleUploadImage(e)}
                              />
                            </div>
                            {/* <a
                              href="edit-profile.php"
                              className="accentColor text-decoration-underline"
                            >
                              Edit Profile
                            </a> */}
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline gap-5 my-3">
                            <label htmlFor="" className="mainLabel">
                              Name:
                            </label>
                            <input
                              type="text"
                              className="mainInput"
                              defaultValue="Brya"
                              {...register("name")}
                            />
                          </div>
                          <span className="text-danger">
                            {errors?.name?.message}
                          </span>
                          {/* <div className="mainDetail d-xl-flex align-items-baseline gap-5 my-3">
                            <label htmlFor="" className="mainLabel">
                              First Name:
                            </label>
                            <input
                              type="text"
                              className="mainInput"
                              defaultValue="Brya"
                            />
                          </div>
                          <div className="mainDetail d-xl-flex align-items-baseline gap-5 my-3">
                            <label htmlFor="" className="mainLabel">
                              Last Name:
                            </label>
                            <input
                              type="text"
                              className="mainInput"
                              defaultValue="Adams"
                            />
                          </div> */}
                          {/* <div className="mainDetail d-xl-flex align-items-baseline gap-5 my-3">
                            <label htmlFor="" className="mainLabel">
                              Email Address:
                            </label>
                            <input
                              type="email"
                              className="mainInput"
                              defaultValue="bryanemail@example.com"
                            />
                          </div> */}
                          <div className="mainDetail text-center my-3 mt-5">
                            <Button
                              type="submit"
                              className="siteBtn"
                              loading={isLoading}
                            >
                              Update
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
