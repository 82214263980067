import axios from "axios";
import { connection_string } from "./connection_strings";
import { getAccessToken, removeAccessToken } from "./authHeader";

const api = axios.create({
  baseURL: connection_string,
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      removeAccessToken();
    }
    return Promise.reject(error);
  }
);

export default api;
