import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import { Confirmation, Error, Success } from "../../Components/Modal";
import { Link } from "react-router-dom";
import useTableControls from "../../Hooks/useTableControls";
import useFetchData from "../../Hooks/useFetchData";
import {
  getVehicleTypes,
  changeVehicleTypeStatus,
} from "../../Services/VehicleType";
import { format_date, getSerialNumber } from "../../Utils/helpers";
import { useMutation } from "react-query";

export default function VehicleTypeManagement() {
  // const sampleData = [
  //   {
  //     sn: "1",
  //     typeName: "Abc Type",
  //     cost: "123",
  //     numberOfVehicles: "02",
  //     datePosted: "01/02/2022",
  //     status: true,
  //   },
  //   {
  //     sn: "2",
  //     typeName: "Abc Type",
  //     cost: "123",
  //     numberOfVehicles: "02",
  //     datePosted: "01/02/2022",
  //     status: false,
  //   },
  //   {
  //     sn: "3",
  //     typeName: "Abc Type",
  //     cost: "123",
  //     numberOfVehicles: "02",
  //     datePosted: "01/02/2022",
  //     status: true,
  //   },
  //   {
  //     sn: "4",
  //     typeName: "Abc Type",
  //     cost: "123",
  //     numberOfVehicles: "02",
  //     datePosted: "01/02/2022",
  //     status: true,
  //   },
  // ];

  const {
    perPage,
    setPerPage,
    status,
    setStatus,
    search_string,
    setSearchString,
    from,
    setFrom,
    to,
    setTo,
  } = useTableControls();

  const {
    // INTERNAL EXPORTS
    setPage,
    // REACT QUERY EXPORTS
    isFetching,
    isLoading,
    data,
    refetch,
  } = useFetchData("vehicleType_logs", getVehicleTypes, [
    perPage,
    search_string,
    status,
    from,
    to,
  ]);
  // console.log("vehicleType_logs Data: ", data?.data);

  const { mutate, isLoading: loadingStatus } = useMutation(
    (id) => changeVehicleTypeStatus(id),
    {
      onSuccess: (res) => {
        refetch();
        Success("Vehicle Type", res.data?.message);
      },
      onError: (err) => {
        Error(err?.response?.data?.message);
      },
    }
  );

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                            Vehicle Type Management
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="text-end">
                        <Link
                          to="/vehicle-type-management/add"
                          className="siteBtn mt-1"
                        >
                          Add New
                        </Link>
                      </div>
                    </div>
                  </div>

                  <Table
                    key={"vehicle-type-management-table"}
                    tableTitles={[
                      "S.No.",
                      "Type Name",
                      "Cost",
                      "Number of Vehicles",
                      "Date Posted",
                      "Status",
                      "Action",
                    ]}
                    dateFilter
                    showEntries
                    sortBy
                    isLoading={isLoading}
                    isFetching={isFetching}
                    data={data?.data?.data?.docs}
                    totalPages={data?.data?.data?.totalPages}
                    totalDocs={data?.data?.data?.totalDocs}
                    setPage={setPage}
                    setSearchString={setSearchString}
                    status={status}
                    setStatus={setStatus}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                  >
                    {!isFetching &&
                      data?.data?.data?.docs?.map((item, index) => (
                        <tr>
                          <td>{getSerialNumber(data?.data?.data, index)}</td>
                          <td>{item.name}</td>
                          <td>${item.baseFare}</td>
                          <td>{item.vehicles}</td>
                          <td>
                            {item?.createdAt &&
                              format_date(item?.createdAt, "MM/DD/YYYY")}
                          </td>
                          <td>{item.active ? "Active" : "Inactive"}</td>
                          <td>
                            <StatusHandler
                              key={`${item?._id}-status`}
                              edit
                              active
                              item={item}
                              isLoading={loadingStatus}
                              redirectUrl={`/vehicle-type-management/edit/${item?._id}`}
                              onClick={() =>
                                Confirmation(
                                  `Are you sure you want to ${
                                    item?.active ? "inactive" : "active"
                                  }  ${item.name}`,
                                  "Yes",
                                  () => mutate(item?._id)
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
