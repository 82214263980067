import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getSetting } from "../../Services/Setting";

export default function Settings() {
  const { isLoading, data } = useQuery("get-setting", getSetting);
  // console.log("get-setting Data", data?.data);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                            Settings
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="text-end">
                        <Link to="/settings/edit" className="siteBtn mt-1">
                          Edit
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-xxl-6">
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Admin's Commission:
                            </label>
                            <p className="mainText">
                              {data?.data?.data?.adminCommission &&
                                `${data?.data?.data?.adminCommission}%`}
                            </p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Base Fare Cost:
                            </label>
                            <p className="mainText">
                              {data?.data?.data?.baseFare &&
                                `$${data?.data?.data?.baseFare}`}
                            </p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Per Km Cost:
                            </label>
                            <p className="mainText">
                              {data?.data?.data?.kmCost &&
                                `$${data?.data?.data?.kmCost}`}
                            </p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Per Fragile Item Cost:
                            </label>
                            <p className="mainText">
                              {data?.data?.data?.fragileCost &&
                                `$${data?.data?.data?.fragileCost}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
