import { Link } from "react-router-dom";

export default function StatusHandler({
  isLoading,
  item,
  onClick,
  redirectUrl,
  view,
  status,
  active,
  approval,
  edit,
}) {
  return (
    <>
      {isLoading ? (
        <i
          className="fas fa-circle-notch fa-spin"
          // style={{ fontSize: 22 }}
        />
      ) : (
        <div className="btn-group">
          <button
            type="button"
            className="btn transparent-btn ellipsis-btn"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {" "}
            <i className="fa fa-ellipsis-v" />
          </button>
          <div className="dropdown-menu text-left custom-dropdown">
            {view && (
              <Link className="dropdown-item" to={redirectUrl}>
                <i className="far fa-eye" />
                View
              </Link>
            )}
            {edit && (
              <Link className="dropdown-item" to={redirectUrl}>
                <i className="fas fa-pen" />
                Edit
              </Link>
            )}
            {status && (
              <a
                className="dropdown-item"
                onClick={onClick}
                // href="#_"
                // data-bs-toggle="modal"
                // data-bs-target="#active-modal"
              >
                <i
                  className={
                    item?.status ? "fas fa-user-times" : "fas fa-user-check"
                  }
                />
                {/* {item?.status === "active" ? "Inactive" : "Active"} */}
                {item?.status ? "Inactive" : "Active"}
              </a>
            )}
            {active && (
              <a
                className="dropdown-item"
                onClick={onClick}
                // href="#_"
                // data-bs-toggle="modal"
                // data-bs-target="#active-modal"
              >
                <i
                  className={
                    item?.active ? "fas fa-user-times" : "fas fa-user-check"
                  }
                />
                {item?.active ? "Inactive" : "Active"}
              </a>
            )}
            {approval && (
              <button
                type="button"
                className="dropdown-item notBtn"
                onClick={onClick}
                // data-bs-toggle="modal"
                // data-bs-target="#approveSignupRequest"
              >
                <i
                  className={
                    item?.requestApprovalStatus === "accepted"
                      ? "fas fa-times"
                      : "fas fa-check"
                  }
                />
                {item?.requestApprovalStatus === "accepted"
                  ? "Reject"
                  : "Accept"}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
