import { Link, useNavigate, useParams } from "react-router-dom";
import { Confirmation, Error, Reason, Success } from "../../Components/Modal";
import Table from "../../Components/Table";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  changeSignupRequestStatus,
  getSignupRequestDetails,
} from "../../Services/SignupRequest";
import Button from "../../Components/Button";
import { format_date } from "../../Utils/helpers";

export default function SignupRequestDetails() {
  // const sampleData = [
  //   {
  //     sn: "1",
  //     bookingId: "123",
  //     moverName: "Mark Carson",
  //     date: "01/02/2022",
  //     pickupLocation: "Abc Location",
  //     dropoffLocation: "Abc Location",
  //     status: "pending",
  //   },
  //   {
  //     sn: "2",
  //     bookingId: "123",
  //     moverName: "Mark Carson",
  //     date: "01/02/2022",
  //     pickupLocation: "Abc Location",
  //     dropoffLocation: "Abc Location",
  //     status: "accepted",
  //   },
  //   {
  //     sn: "3",
  //     bookingId: "123",
  //     moverName: "Mark Carson",
  //     date: "01/02/2022",
  //     pickupLocation: "Abc Location",
  //     dropoffLocation: "Abc Location",
  //     status: "completed",
  //   },
  //   {
  //     sn: "4",
  //     bookingId: "123",
  //     moverName: "Mark Carson",
  //     date: "01/02/2022",
  //     pickupLocation: "Abc Location",
  //     dropoffLocation: "Abc Location",
  //     status: "cancelled",
  //   },
  // ];

  const navigate = useNavigate();
  const { id } = useParams();
  const [requestDetail, setRequestDetail] = useState({});

  const { data, isLoading, refetch } = useQuery(
    ["signupRequest_details", id],
    () => getSignupRequestDetails(id)
  );

  useEffect(() => {
    if (data?.data?.data)
      setRequestDetail(data?.data?.data);
  }, [data]);

  const { mutate, isLoading: loadingStatus } = useMutation(
    (data) => changeSignupRequestStatus(id, data),
    {
      onSuccess: (res) => {
        refetch();
        Success("Signup Request Status", res.data?.message);
      },
      onError: (err) => {
        Error(err?.response?.data?.message);
      },
    }
  );

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0">
                            Muvrrs Management
                          </h1>
                          <span className="mx-2">
                            <button
                              type="button"
                              className="backLink mr-1"
                              onClick={() => navigate(-1)}
                            >
                              <i className="fas fa-chevron-left" />
                            </button>
                            Signup Request
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="row dashCard2 py-3 px-4">
                        <div className="col-xl-3">
                          <div className="userIimageFrame my-2 mx-xl-auto">
                            <img
                              src={
                                requestDetail?.user?.profileImage?.imageUrl ??
                                "images/avatar.png"
                              }
                              alt=""
                              className="userImage img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Full Name:
                            </label>
                            <p className="mainText">
                              {requestDetail?.user?.firstName}
                            </p>
                          </div>
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Email Address:
                            </label>
                            <p className="mainText">
                              {requestDetail?.user?.email}
                            </p>
                          </div>
                          {/* <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Total Bookings:
                            </label>
                            <p className="mainText">0</p>
                          </div> */}
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Phone Number:
                            </label>
                            <p className="mainText">
                              {requestDetail?.user?.phone}
                            </p>
                          </div>
                          {/* <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              SSN:
                            </label>
                            <p className="mainText">000-000-0000</p>
                          </div> */}
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Status:
                            </label>
                            <p className="mainText text-capitalize">{requestDetail?.status}</p>
                          </div>
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Date:
                            </label>
                            <p className="mainText">{format_date(requestDetail?.createdAt, "DD/MM/YYYY")}</p>
                          </div>
                          <div className="mainDetail d-flex align-items-start flex-wrap my-1">
                            <label htmlFor="" className="mainLabel">
                              License Images:
                            </label>
                            <img
                              src={
                                requestDetail?.user?.licenseImageFront?.imageUrl ??
                                "images/truck1.png"
                              }
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src={
                                requestDetail?.user?.licenseImageBack?.imageUrl ??
                                "images/truck1.png"
                              }
                              alt=""
                              className="mainImage"
                            />
                            {/* <img
                              src="images/truck2.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/truck3.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/truck4.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/truck2.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/truck4.png"
                              alt=""
                              className="mainImage"
                            /> */}
                          </div>
                          <div className="mainDetail d-flex align-items-start flex-wrap my-1">
                            <label htmlFor="" className="mainLabel">
                              Insurance Image:
                            </label>
                            <img
                              src={
                                requestDetail?.user?.insurance?.imageUrl ??
                                "images/truck1.png"
                              }
                              alt=""
                              className="mainImage"
                            />
                          </div>
                          <div className="mainDetail my-1">
                            <Link
                              to="/mover-management/signup-requests"
                              className="siteBtn2 mt-1"
                            >
                              Signup Requests
                            </Link>
                            {requestDetail?.status == "pending" && (
                              <>
                                <Button
                                  type="button"
                                  className="siteBtn ms-1 mt-1"
                                  loading={loadingStatus}
                                  onClick={() => {
                                    Confirmation(
                                      `Are You Sure You Want To Approve The Signup Request ?`,
                                      "Yes",
                                      () => mutate({ status: "approved" })
                                    );
                                  }}
                                // data-bs-toggle="modal"
                                // data-bs-target="#approveSignupRequest"
                                >
                                  Approve
                                </Button>
                                <Button
                                  type="button"
                                  className="siteBtn ms-1 mt-1"
                                  loading={loadingStatus}
                                  onClick={() => {
                                    Reason(
                                      "Are you sure you want to reject the signup request ?",
                                      "Yes",
                                      (val) =>
                                        mutate({
                                          status: "rejected",
                                          reason: val,
                                        }),
                                      "Please enter your rejection reason"
                                    );
                                  }}
                                // data-bs-toggle="modal"
                                // data-bs-target="#approveSignupRequest"
                                >
                                  Reject
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Table
                    key={"signup-request-details-table"}
                    tableTitles={[
                      "S.No.",
                      "Booking ID",
                      "Muvrrs Name",
                      "Date",
                      "Pickup Location",
                      "Drop-Off Location",
                      "Status",
                      // "Action",
                    ]}
                    dateFilter
                    showEntries
                    sortBy
                    // isLoading={isLoading}
                    // isFetching={isFetching}
                    // data={data?.data?.users}
                    // totalPages={data?.data?.totalPages}
                    // totalDocs={data?.data?.totalDocs}
                    // setPage={setPage}
                    // setSearchString={setSearchString}
                    // status={status}
                    // setStatus={setStatus}
                    // perPage={perPage}
                    // setPerPage={setPerPage}
                    // from={from}
                    // setFrom={setFrom}
                    // to={to}
                    // setTo={setTo}
                  >
                    {sampleData?.map((item, index) => (
                      <tr>
                        <td>{item.sn}</td>
                        <td>{item.bookingId}</td>
                        <td>{item.moverName}</td>
                        <td>{item.date}</td>
                        <td>{item.pickupLocation}</td>
                        <td>{item.dropoffLocation}</td>
                        <td>{item.status}</td>
                      </tr>
                    ))}
                  </Table> */}

                  {/* <div className="row mb-3">
                    <div className="col-12">
                      <div className="dataTables_length text-start mb-4">
                        <div className="user-listing-top dashCard2 py-4 px-4">
                          <div className="d-xxl-flex align-items-end justify-content-between mb-xxl-3">
                            <div className="userInput mb-3">
                              <div className="d-md-flex align-items-center">
                                <label
                                  htmlFor=""
                                  className="dashLabel mb-3 mb-xxl-0 me-3"
                                >
                                  Sort By Date
                                </label>
                                <div className="d-sm-flex">
                                  <div className="mb-2 mb-xxl-0 me-sm-3">
                                    <input
                                      className="dashInput p-3"
                                      type="date"
                                    />
                                  </div>
                                  <div className="mb-2 mb-xxl-0">
                                    <input
                                      className="dashInput p-3"
                                      type="date"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="userInput mb-2">
                              <div className="form-field mb-3">
                                <div className="d-xxl-flex align-items-center justify-content-end">
                                  <div className="dataTables_filter">
                                    <div className="dashSearchWrap">
                                      <form action="" method="POST">
                                        <input
                                          type="search"
                                          id="search-inp"
                                          className="dashInput search-input w-100"
                                          placeholder="Search"
                                        />
                                        <button
                                          type="submit"
                                          className="search-icon"
                                        >
                                          <i className="fas fa-search" />
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-xxl-flex align-items-end flex-wrap justify-content-between mb-xxl-3">
                            <div className="userInput mb-3">
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0 me-3">
                                Show
                              </label>
                              <select className="d-inline-block dashInput sm-dropdown">
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0 ms-3">
                                Entries
                              </label>
                            </div>
                            <div className="userInput mb-3">
                              <label className="dashLabel d-inline-block mb-3 mb-xxl-0">
                                Filter By:
                              </label>
                              <select className="d-inline-block dashInput">
                                <option
                                  value=""
                                  disabled=""
                                  hidden=""
                                  selected=""
                                >
                                  All
                                </option>
                                <option value={1}>Active</option>
                                <option value={2}>Inactive</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="maain-tabble table-responsive">
                        <table className="table table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>S No.</th>
                              <th>Booking ID</th>
                              <th>Muvrrs Name</th>
                              <th>Date</th>
                              <th>Pickup Location</th>
                              <th>Drop-Off Location</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>123</td>
                              <td>Daniel Jacob</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>ABC Location</td>
                              <td>Pending</td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn transparent-btn ellipsis-btn"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {" "}
                                    <i className="fa fa-ellipsis-v" />
                                  </button>
                                  <div className="dropdown-menu text-left custom-dropdown">
                                    <a
                                      className="dropdown-item"
                                      href="view-signup-requests.php"
                                    >
                                      <i className="far fa-eye" />
                                      View
                                    </a>
                                    <button
                                      type="button"
                                      className="dropdown-item notBtn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#approveSignupRequest"
                                    >
                                      <i className="fas fa-check" />
                                      Accept
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>123</td>
                              <td>Daniel Jacob</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>ABC Location</td>
                              <td>Accepted</td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn transparent-btn ellipsis-btn"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {" "}
                                    <i className="fa fa-ellipsis-v" />
                                  </button>
                                  <div className="dropdown-menu text-left custom-dropdown">
                                    <a
                                      className="dropdown-item"
                                      href="view-signup-requests.php"
                                    >
                                      <i className="far fa-eye" />
                                      View
                                    </a>
                                    <button
                                      type="button"
                                      className="dropdown-item notBtn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#approveSignupRequest"
                                    >
                                      <i className="fas fa-check" />
                                      Accept
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>123</td>
                              <td>Daniel Jacob</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>ABC Location</td>
                              <td>Completed</td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn transparent-btn ellipsis-btn"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {" "}
                                    <i className="fa fa-ellipsis-v" />
                                  </button>
                                  <div className="dropdown-menu text-left custom-dropdown">
                                    <a
                                      className="dropdown-item"
                                      href="view-signup-requests.php"
                                    >
                                      <i className="far fa-eye" />
                                      View
                                    </a>
                                    <button
                                      type="button"
                                      className="dropdown-item notBtn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#approveSignupRequest"
                                    >
                                      <i className="fas fa-check" />
                                      Accept
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>123</td>
                              <td>Daniel Jacob</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>ABC Location</td>
                              <td>Cancelled</td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn transparent-btn ellipsis-btn"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {" "}
                                    <i className="fa fa-ellipsis-v" />
                                  </button>
                                  <div className="dropdown-menu text-left custom-dropdown">
                                    <a
                                      className="dropdown-item"
                                      href="view-signup-requests.php"
                                    >
                                      <i className="far fa-eye" />
                                      View
                                    </a>
                                    <button
                                      type="button"
                                      className="dropdown-item notBtn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#approveSignupRequest"
                                    >
                                      <i className="fas fa-check" />
                                      Accept
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>123</td>
                              <td>Daniel Jacob</td>
                              <td>mm/dd/yyyy</td>
                              <td>Abc Location</td>
                              <td>ABC Location</td>
                              <td>Pending</td>
                              <td>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className="btn transparent-btn ellipsis-btn"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {" "}
                                    <i className="fa fa-ellipsis-v" />
                                  </button>
                                  <div className="dropdown-menu text-left custom-dropdown">
                                    <a
                                      className="dropdown-item"
                                      href="view-signup-requests.php"
                                    >
                                      <i className="far fa-eye" />
                                      View
                                    </a>
                                    <button
                                      type="button"
                                      className="dropdown-item notBtn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#rejectSignupRequest"
                                    >
                                      <i className="fas fa-times" />
                                      Reject
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="showing-result"
                        id="DataTables_Table_0_info"
                      >
                        Showing 10 of 50 Entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <ul className="pagination justify-content-end">
                          <li className="paginate_button page-item previous">
                            <button type="button" className="page-link notBtn">
                              Previous
                            </button>
                          </li>
                          <li className="paginate_button page-item active">
                            <button type="button" className="page-link notBtn">
                              01
                            </button>
                          </li>
                          <li className="paginate_button page-item">
                            <button type="button" className="page-link notBtn">
                              02
                            </button>
                          </li>
                          <li className="paginate_button page-item">
                            <button type="button" className="page-link notBtn">
                              03
                            </button>
                          </li>
                          <li className="paginate_button page-item next">
                            <button type="button" className="page-link notBtn">
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div
              className="modal fade"
              id="approveSignupRequest"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/question.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Are you sure you want to approve the signup request
                            ?
                          </p>
                        </div>
                      </div>
                      <div className="modalAction">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="siteBtn2 mx-1"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          data-bs-toggle="modal"
                          data-bs-target="#approvedSignupRequest"
                          className="siteBtn mx-1"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="approvedSignupRequest"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/check.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Signup request has been approved successfully
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="rejectSignupRequest"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/question.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Are you sure you want to reject the signup request ?
                          </p>
                        </div>
                      </div>
                      <form action="">
                        <div className="modalForm">
                          <div className="text-center">
                            <h3 className="sectionTitle">Reject Reason</h3>
                          </div>
                          <textarea
                            name=""
                            id=""
                            rows={4}
                            className="modalInput"
                            defaultValue={""}
                          />
                        </div>
                        <div className="modalAction my-3">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            className="siteBtn2 mx-1"
                          >
                            No
                          </button>
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#rejectedSignupRequest"
                            className="siteBtn mx-1"
                          >
                            Yes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="rejectedSignupRequest"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/times.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Signup request has been rejected
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>
    </div>
  );
}
