import { useNavigate, useParams } from "react-router-dom";
import { Confirmation } from "../../Components/Modal";
import VehicleDetail from "./VehicleDetail";
import DriverDetail from "./DriverDetail";
import { getRideDetails } from "../../Services/Ride";
import { getReviewByBookingId } from "../../Services/Review";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Map from "../../Components/Map";
import { format_date } from "../../Utils/helpers";
import { getSetting } from "../../Services/Setting";

export default function RideDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rideDetail, setRideDetail] = useState({});

  const { data, isLoading, refetch } = useQuery(["ride-details", id], () =>
    getRideDetails(id)
  );
  const { data: settings } = useQuery("get-setting", getSetting);
  // console.log("ride-details: ", data?.data);

  useEffect(() => {
    if (data?.data?.data?.docs?.length > 0)
      setRideDetail(data?.data?.data?.docs?.[0]);
  }, [data?.data?.data?.docs]);

  const {
    data: reviewData,
    isLoading: reviewLoading,
    refetch: reviewRefetch,
  } = useQuery(["booking-review", id], () => getReviewByBookingId(id));
  // console.log("booking-review: ", reviewData?.data);

  return (
    <>
      <div className="app-content content dashboard">
        <div className="content-wrapper">
          <div className="content-body">
            {/* Basic form layout section start */}
            <section id="configuration">
              <div className="row">
                <div className="col-12">
                  <div className="card-content collapse show dashCard pt-5 px-5">
                    <div className="row mb-4">
                      <div className="col-12">
                        <div className="back-title">
                          <div className="pageTitleInner d-flex align-items-baseline">
                            <h1 className="pageTitle text-capitalize m-0">
                              Rides Management
                            </h1>
                            <span className="mx-2">
                              <button
                                type="button"
                                className="backLink mr-1"
                                onClick={() => navigate(-1)}
                              >
                                <i className="fas fa-chevron-left" />
                              </button>
                              Ride Details
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row dashCard2 flex-column-reverse flex-xxl-row mb-4 px-4 py-3">
                      <div className="col-xxl-6">
                        <p className="rideTime my-2">
                          {/* Booking for Aug 30, 2021, 00:00 PM */}
                          {rideDetail?.createdAt &&
                            `Booking for ${format_date(
                              rideDetail?.createdAt,
                              "MMM DD, YYYY, hh:mm A"
                            )}`}
                        </p>
                      </div>
                      <div className="col-xxl-6">
                        <div className="text-end">
                          <div className="statusBox my-2">
                            <p className="m-0">
                              Status:{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {rideDetail?.status}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12 my-2">
                        <div className="d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0">
                            {rideDetail?.user?.firstName}
                          </h1>
                          <span className="mx-2">
                            {rideDetail?._id && `#${rideDetail?._id}`}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 my-2">
                        <Map
                          originLat={
                            rideDetail?.bookingDetails?.pickupLocation?.lat
                          }
                          originLng={
                            rideDetail?.bookingDetails?.pickupLocation?.lng
                          }
                          destinationLat={
                            rideDetail?.bookingDetails?.dropOffLocation?.lat
                          }
                          destinationLng={
                            rideDetail?.bookingDetails?.dropOffLocation?.lng
                          }
                        // originLat={24.859392495364922}
                        // originLng={67.05242870873693}
                        // destinationLat={24.859806296562997}
                        // destinationLng={67.0582772654939}
                        />
                        {/* <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50470.02846874147!2d-122.47261937941492!3d37.75776267828957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1649050746012!5m2!1sen!2s"
                          width="100%"
                          height="400px"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        /> */}
                      </div>
                      <div className="dashCard2 px-4 py-3">
                        <div className="d-flex flex-wrap">
                          <div className="locationBox">
                            <i className="fas fa-clock locationIcon accentColor" />
                            <div className="locationDetail">
                              <h4>Pick-up Location</h4>
                              <p>
                                {
                                  rideDetail?.bookingDetails?.pickupLocation
                                    ?.name
                                }
                              </p>
                            </div>
                          </div>
                          <div className="locationBox">
                            <i className="fas fa-map-marker-alt locationIcon accentColor" />
                            <div className="locationDetail">
                              <h4>Drop-off Location</h4>
                              <p>
                                {
                                  rideDetail?.bookingDetails?.dropOffLocation
                                    ?.name
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-xxl-10">
                        <div className="packageDetailBox my-3">
                          <h5 className="packageHeading fw-bold">Item</h5>
                          <p
                            className=""
                            style={{ textTransform: "capitalize" }}
                          >
                            {/* Beds, Sofa, Dining Table */}
                            {rideDetail?.bookingDetails?.package?.item?.length >
                              0 &&
                              rideDetail?.bookingDetails?.package?.item?.map(
                                (item) => item
                              )}
                          </p>
                          <div className="mainDetail d-flex align-items-baseline flex-wrap my-1">
                            {rideDetail?.bookingDetails?.package?.images
                              ?.length > 0 &&
                              rideDetail?.bookingDetails?.package?.images?.map(
                                (item) => (
                                  <img
                                    src={item?.imageUrl}
                                    alt=""
                                    className="mainImage"
                                  />
                                )
                              )}
                            {/* <img
                              src="images/package1.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/package2.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/package3.png"
                              alt=""
                              className="mainImage"
                            />
                            <img
                              src="images/package4.png"
                              alt=""
                              className="mainImage"
                            /> */}
                          </div>
                        </div>
                        <div className="d-flex gap-5">
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold">Load Weight</h5>
                            <p className="">
                              {rideDetail?.bookingDetails?.package?.weight &&
                                `${rideDetail?.bookingDetails?.package?.weight}lbs`}
                            </p>
                          </div>
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold">Cost</h5>
                            <p className="">
                              {rideDetail?.bookingDetails?.price &&
                                `$${rideDetail?.bookingDetails?.price?.toFixed(
                                  2
                                )}`}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex gap-5">
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold">Admin Commission</h5>
                            <p className="">
                              {rideDetail?.bookingDetails?.price &&
                                `$ ${(rideDetail?.bookingDetails?.price?.toFixed(2) - (rideDetail?.bookingDetails?.price?.toFixed(2) - ((rideDetail?.bookingDetails?.price?.toFixed(2) * Number(settings?.data?.data?.adminCommission)) / 100))).toFixed(2)}`}
                            </p>
                          </div>
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold">Base Fare</h5>
                            <p className="">
                              {rideDetail?.bookingDetails?.price &&
                                `$${Number(settings?.data?.data?.baseFare)}`}
                            </p>
                          </div>
                          {
                            rideDetail?.bookingDetails?.fragile &&
                            <div className="packageDetailBox my-3">
                              <h5 className="packageHeading fw-bold">Fragile Cost</h5>
                              <p className="">
                                {rideDetail?.bookingDetails?.fragile &&
                                  `$${Number(settings?.data?.data?.fragileCost)}`}
                              </p>
                            </div>
                          }
                          <div className="packageDetailBox my-3">
                            <h5 className="packageHeading fw-bold">Total Cost</h5>
                            <p className="">
                              {rideDetail?.bookingDetails?.price &&
                                `$${rideDetail?.bookingDetails?.price?.toFixed(
                                  2
                                )}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-xxl-10">
                        <div className="d-flex gap-5 flex-wrap">
                          <div className="dashCard2 d-flex justify-content-between flex-grow-1 gap-5 py-3 px-4">
                            <div className="packageDetail1 flex-shrink-0">
                              <p className="mainText2 fw-bold mb-2">
                                Vehicle Information
                              </p>
                              <p className="packageDetail accentColor mb-2 text-capitalize">
                                {rideDetail?.driver?.vehicle?.name}
                              </p>
                              <p className="mainText2 mb-2">
                                {
                                  rideDetail?.driver?.vehicle?.numberPlate &&
                                  <>
                                    <span className="fw-bold">Plate No: </span> {rideDetail?.driver?.vehicle?.numberPlate}
                                  </>
                                }
                              </p>
                              <p className="mainText2 mb-2">
                                {rideDetail?.driver?.vehicle?.type?.name &&
                                  <>
                                    <span className="fw-bold">Vehicle Type: </span> {rideDetail?.driver?.vehicle?.type?.name}
                                  </>
                                }
                              </p>
                              <p className="mainText2 mb-2">
                                {
                                  rideDetail?.driver?.vehicle?.color &&
                                  <>
                                    <span className="fw-bold">Colour: </span> {rideDetail?.driver?.vehicle?.color}
                                  </>
                                }
                              </p>
                              {/* <p className="mainText2 mb-2">
                                123 Weight Capacity
                              </p> */}
                            </div>
                            <div className="packageDetail2">
                              <a
                                // href=""
                                // className="accentColor text-decoration-underline"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#vehicleDetails"
                                className="notBtn accentColor d-block w-100 text-decoration-underline"
                              >
                                View Details
                              </a>
                            </div>
                          </div>
                          <div className="dashCard2 d-flex justify-content-between flex-grow-1 gap-5 py-3 px-4">
                            <div className="packageDetail1 flex-shrink-0">
                              <p className="mainText2 fw-bold mb-2">
                                Muvrr's Information
                              </p>
                              <p
                                className="packageDetail accentColor mb-2"
                                style={{ textTransform: "capitalize" }}
                              >
                                {rideDetail?.driver?.firstName &&
                                  `${rideDetail?.driver?.firstName}`}
                              </p>
                              <p className="mainText2 mb-2">
                                {rideDetail?.driver?.email}
                              </p>
                              {/* <p className="mainText2 mb-2">
                                Light Vechile Type
                              </p>
                              <p className="mainText2 mb-2">Black Color</p>
                              <p className="mainText2 mb-2">
                                123 Weight Capacity
                              </p> */}
                            </div>
                            <div className="packageDetail2 text-center">
                              <a
                                // href=""
                                // className="accentColor d-block text-decoration-underline mb-2"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#driverDetails"
                                className="notBtn accentColor d-block w-100 text-decoration-underline"
                              >
                                View Details
                              </a>
                              <img
                                src={
                                  rideDetail?.driver?.profileImage?.imageUrl ??
                                  "images/mover.png"
                                }
                                alt=""
                                className="img-fluid mb-2 moverImg"
                              />
                              {/* <p className="mainText2">
                                <i className="fa-star fas goldColor mx-1" />5
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="dashCard2 d-flex justify-content-between flex-grow-1 gap-5 py-3 px-4">
                        <div className="packageDetail1 flex-shrink-0">
                          <p className="mainText2 fw-bold mb-2">
                            Delivery Details
                          </p>
                          <h6 className="fw-bold mb-2">
                            Delivery Images
                          </h6>
                          {
                            rideDetail?.deliveredImages?.map(item => (
                              <img
                                src={
                                  item?.imageUrl ??
                                  "images/mover.png"
                                }
                                alt=""
                                className="img-fluid mb-2 moverImg mx-2"
                              />
                            ))
                          }
                          <h6 className="fw-bold mb-2">
                            Driver Signature
                          </h6>
                          <img
                            src={
                              rideDetail?.signature?.imageUrl ??
                              "images/mover.png"
                            }
                            alt=""
                            className="img-fluid mb-2 moverImg"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      {reviewData?.data?.data !== null && (
                        <div className="dashCard2 flex-grow-1 py-3 px-4">
                          <div className="packageDetail1 flex-shrink-0">
                            <p className="mainText2 mb-2">User Review</p>
                            <span>
                              {reviewData?.data?.data?.rating > 0 &&
                                new Array(reviewData?.data?.data?.rating)
                                  ?.fill(5)
                                  ?.map(() => (
                                    <i className="fa-star fas goldColor mx-1" />
                                  ))}
                              {/* <i className="fa-star fas goldColor mx-1" />
                                  <i className="fa-star fas goldColor mx-1" />
                                  <i className="fa-star fas goldColor mx-1" />
                                  <i className="fa-star fas goldColor mx-1" />
                                  <i className="fa-star fas goldColor mx-1" /> */}
                            </span>
                            <p>{reviewData?.data?.data?.message}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <VehicleDetail data={rideDetail?.driver} />
      <DriverDetail data={rideDetail?.driver} />
    </>
  );
}
