import api from "../Utils/axios";
import { filterParams } from "../Utils/helpers";

export const getEarnings = (page, perPage, search_string, status, from, to) => {
  return api.get(`/user/earnings`, {
    params: filterParams({
      page,
      limit: perPage,
      keyword: search_string,
      status,
      "createdAt[from]": from,
      "createdAt[to]": to,
    }),
  });
};
