import api from "../Utils/axios";

export const login = (data) => {
  return api.post(`/user/login`, data);
};

export const recoverPassword = (data) => {
  return api.post(`/user/requestForgetPass`, data);
};

export const verifyCode = (data) => {
  return api.post(`/user/verifyOtp`, data);
};

export const resetPassword = (data) => {
  return api.post(`/user/resetPass`, data);
};

export const logout = () => {
  return api.post(`/user/logout`);
};
