import * as yup from "yup";

export const changePasswordSchema = yup
  .object({
    password: yup.string().required("Password is required"),
    newPassword: yup.string().max(20).required("New password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords doesn't match"),
  })
  .required();

export const editProfileSchema = yup
  .object({
    name: yup.string().required("First name is required"),
  })
  .required();
