import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";
import { settingSchema } from "../../Schema/generalSchema";
import { getSetting } from "../../Services/Setting";

export default function EditSetting() {
  const navigate = useNavigate();
  const { isLoading, data } = useQuery("get-setting", getSetting);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(settingSchema),
    defaultValues: {
      adminCommission: Number(data?.data?.data?.adminCommission),
      baseFare: Number(data?.data?.data?.baseFare),
      kmCost: Number(data?.data?.data?.kmCost),
      fragileCost: Number(data?.data?.data?.fragileCost),
    }
  });
  const submit = (data) => {
    // console.log(data, "data");
    console.log({
      adminCommission: Number(data?.adminCommission),
      baseFare: Number(data?.baseFare),
      kmCost: Number(data?.kmCost),
      fragileCost: Number(data?.fragileCost),
    });

    navigate("/settings");
  };
  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0">
                            Settings
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-xxl-6">
                          {/* <form action="settings.php"> */}
                          <form onSubmit={handleSubmit(submit)}>
                            <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                              <label
                                htmlFor=""
                                className="mainLabel mainLabel-lg"
                              >
                                Admin's Commission
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                // defaultValue={10}
                                className="mainInput"
                                {...register("adminCommission")}
                              />
                            </div>
                            <span className="text-danger">
                              {errors?.adminCommission?.message}
                            </span>
                            <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                              <label
                                htmlFor=""
                                className="mainLabel mainLabel-lg"
                              >
                                Base Fare Cost:
                              </label>
                              <input
                                type="number"
                                // defaultValue={1234}
                                className="mainInput"
                                {...register("baseFare")}
                              />
                            </div>
                            <span className="text-danger">
                              {errors?.baseFare?.message}
                            </span>
                            <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                              <label
                                htmlFor=""
                                className="mainLabel mainLabel-lg"
                              >
                                Per Km Cost:
                              </label>
                              <input
                                type="number"
                                // defaultValue={1234}
                                className="mainInput"
                                {...register("kmCost")}
                              />
                            </div>
                            <span className="text-danger">
                              {errors?.kmCost?.message}
                            </span>
                            <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                              <label
                                htmlFor=""
                                className="mainLabel mainLabel-lg"
                              >
                                Per Fragile Item Cost:
                              </label>
                              <input
                                type="number"
                                // defaultValue={1234}
                                className="mainInput"
                                {...register("fragileCost")}
                              />
                            </div>

                            <span className="text-danger">
                              {errors?.fragileCost?.message}
                            </span>
                            <div className="mainDetail d-flex align-items-baseline justify-content-between py-2 px-3 my-3">
                              <label
                                htmlFor=""
                                className="mainLabel mainLabel-lg"
                              />
                              <Button
                                type="submit"
                                className="siteBtn"
                              // onClick={() => navigate("/settings")}
                              >
                                Update
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
