import Swal from "sweetalert2";

export const Success = (title = "SUCCESS", message) =>
  Swal.fire({
    icon: "success",
    title,
    text: `${message ? message : "Completed"}`,
    showConfirmButton: false,
    timer: 3000,
    background: "#040404",
    color: "#fff",
    width: 500,
    customClass: {
      title: "modal-text mt-1 fw-regular text-white",
    },
  });

export const Confirmation = (title, confirmButtonText, successFunction) => {
  return Swal.fire({
    title,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText: "No",
    customClass: {
      confirmButton: "general-btn me-md-3 mb-2",
      cancelButton: "general-btn-3 mb-2",
      title: "modal-text mt-1 fw-regular",
    },
    background: "#040404",
    color: "#fff",
    width: 500,
    heightAuto: true,
    icon: "question",
  }).then((result) => {
    if (result.isConfirmed) {
      successFunction();
    }
  });
};

export const Error = (err) => {
  return Swal.fire({
    icon: "error",
    title: "ERROR",
    text: err ? err : "Internal Server Error",
    showConfirmButton: false,
    timer: 3000,
    background: "#040404",
    color: "#fff",
    width: 500,
  });
};

export const AddModal = (
  title = "Add",
  labelText = "Name",
  confirmButtonText = "CREATE",
  validationText = "You need to write something!",
  successFunction
) =>
  Swal.fire({
    input: "text",
    icon: "",
    title,
    html: `<div class="text-start"><label for="" class="input-lbl">${labelText}<span>*</span></label></div>`,
    // html: '<div class="text-start"><label for="" class="input-lbl">Event Type Name<span>*</span></label><input type="text" placeholder="Enter Event Type Name" class="all-input w-100"></div>',
    confirmButtonText: confirmButtonText,
    customClass: {
      confirmButton: "general-btn",
      title: "modal-text mt-2 fw-regular",
    },
    background: "#fff",
    color: "#fff",
    width: 500,
    inputValidator: (value) => {
      if (!value) {
        return validationText;
      }
    },
  }).then((result) => {
    if (result.value && result.isConfirmed) {
      successFunction(result.value);
    }
  });

export const Reason = (
  title = "SUCCESS",
  confirmButtonText,
  successFunction,
  inputValidationMsg = "You need to write something!"
) =>
  Swal.fire({
    input: "text",
    icon: "info",
    title,
    showConfirmButton: true,
    confirmButtonText: confirmButtonText,
    customClass: {
      confirmButton:
        "main-modalbtn mb-2 me-md-3 .swal2-styled.swal2-confirm:focus",
      title: "modal-text mt-2 fw-lighter",
    },
    background: "white",
    color: "black",
    width: 595,
    inputValidator: (value) => {
      if (!value) {
        return inputValidationMsg;
      }
    },
  }).then((result) => {
    if (result.value && result.isConfirmed) {
      successFunction(result.value);
    }
  });
