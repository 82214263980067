import { useSelector } from "react-redux";
import { format_date, getMongoUTCDate } from "../../Utils/helpers";
import { socket } from "../../Utils/socket";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { sendMessage } from "../../Services/Chat";
import Button from "../../Components/Button";

export default function Message({
  data,
  dataLoading,
  dataFetching,
  setChat,
  setMessagesEnfRef,
}) {
  const { user } = useSelector((state) => state?.slice);
  const [message, setMessage] = useState("");
  const messagesEnd = useRef(null);

  const { mutate, isLoading: loadingStatus } = useMutation(
    (data) => sendMessage(data),
    {
      onSuccess: (res) => {
        // console.log("SendM Res: ", res.data);
        let temp = data?.length > 0 && data;
        temp.push(res?.data?.message);
        // const obj = {
        //   conversationId: data && data?.[0]?.conversationId,
        //   sender: user && user,
        //   message: message && message,
        //   createdAt: getMongoUTCDate(),
        //   updatedAt: getMongoUTCDate(),
        //   __v: 0,
        // };
        // temp.push(obj);
        setChat(temp);
        setMessage("");
        setTimeout(
          () => messagesEnd?.current?.scrollIntoView({ behavior: "smooth" }),
          500
        );
      },
      onError: (err) => {
        // console.log("Err: ", err?.response?.data);
        const obj = {
          conversationId: data && data?.[0]?.conversationId,
          sender: user && user,
          message: message && message,
          createdAt: getMongoUTCDate(),
          updatedAt: getMongoUTCDate(),
          __v: 0,
        };
        let temp = data?.length > 0 && data;
        temp.push(obj);
        setChat(temp);
        setMessage("");
        setTimeout(
          () => messagesEnd?.current?.scrollIntoView({ behavior: "smooth" }),
          500
        );
      },
    }
  );

  useEffect(() => {
    socket.on("new-message", (data) => {
      setChat((p) => [...p, data]);

      setTimeout(
        () => messagesEnd?.current?.scrollIntoView({ behavior: "smooth" }),
        1000
      );
    });

    return () => {
      socket.off("new-message");
    };
  }, []);

  // useEffect(() => {
  //   if (messagesEnd?.current) {
  //     setMessagesEnfRef && setMessagesEnfRef(messagesEnd);
  //   }
  // }, [messagesEnd?.current === null]);

  return (
    <main id="chat-main">
      <ul id="chat">
        {/* {dataLoading || dataFetching ? (
          <div style={{ textAlign: "center" }}>
            <i
              className="fas fa-circle-notch fa-spin"
              style={{
                fontSize: 42,
                marginTop: "5em",
              }}
            />
          </div>
        ) : data?.length > 0 ? ( */}
        {data?.length > 0 ? (
          data?.map((item) => (
            <li
              key={`${item?._id}-message`}
              className={`${
                user?._id?.toString() ===
                (item?.sender?._id?.toString() || item?.sender?.toString())
                  ? "me"
                  : "you"
              }`}
              ref={messagesEnd}
            >
              <div className="media message d-flex">
                <div className="media-left flex-shrink-0 text-center me-3 align-self-start">
                  <img
                    src={
                      item?.sender?.profileImage?.imageUrl ??
                      "images/userImage.png"
                    }
                    alt=""
                  />
                </div>
                <div className="media-body align-self-center flex-grow-1">
                  <div className="post-comment">{item?.message}</div>
                  <div className="meta d-flex align-items-center">
                    <p className="post-date ml-auto">
                      {item?.createdAt &&
                        format_date(item?.createdAt, "hh:mm A")}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          <div className="media message d-flex">
            <div className="media-body align-self-center flex-grow-1">
              <div className="">No Messages Found</div>
            </div>
          </div>
        )}
      </ul>

      <footer id="chat-footer">
        <div className="message-area">
          <textarea
            type="text"
            placeholder="Write your message..."
            value={message}
            onChange={({ target }) => setMessage(target.value)}
          ></textarea>
          <Button
            className="reply-btn ms-auto"
            onClick={() =>
              mutate({
                conversationId: data && data?.[0]?.conversationId,
                message,
              })
            }
            loading={loadingStatus}
          >
            <i className="fa fa-paper-plane" aria-hidden="true"></i>
          </Button>
        </div>
      </footer>
    </main>
    // <main id="chat-main">
    //   {/* Working */}
    //   {/* <header className="contact-profile">
    //     <img
    //         src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_01.jpg"
    //         alt=""
    //     />
    //     <div>
    //         <h2>Chat with Vincent Porter</h2>
    //         <h3>already 1902 messages</h3>
    //         <i className="fas fa-wifi-1"></i>
    //         <span className="contact-status online"></span>
    //     </div>
    //     <img
    //         src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_star.png"
    //         alt=""
    //     />
    //     </header> */}

    //   <ul id="chat">
    //     <li className="you">
    //       <div className="media message d-flex">
    //         <div className="media-left flex-shrink-0 text-center me-3 align-self-start">
    //           <img
    //             src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_04.jpg"
    //             alt=""
    //           />
    //         </div>
    //         <div className="media-body align-self-center flex-grow-1">
    //           <div className="post-comment">
    //             Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //             Cupiditate delectus expedita, exercitationem veniam dolor
    //             similique autem illum incidunt.
    //           </div>
    //           <div className="meta d-flex align-items-center">
    //             <p className="post-date ml-auto">8:20 PM</p>
    //           </div>
    //         </div>
    //       </div>
    //     </li>
    //     <li className="me">
    //       <div className="media message d-flex">
    //         <div className="media-left flex-shrink-0 text-center me-3 align-self-start">
    //           <img
    //             src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_04.jpg"
    //             alt=""
    //           />
    //         </div>
    //         <div className="media-body align-self-center flex-grow-1">
    //           <div className="post-comment">
    //             Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //             Cupiditate delectus expedita, exercitationem veniam dolor
    //             similique autem illum incidunt.
    //           </div>
    //           <div className="meta d-flex align-items-center">
    //             {/* <h6 className="mt-0 mb-0">Elsa Robert</h6> */}
    //             <p className="post-date ml-auto">8:20 PM</p>
    //           </div>
    //         </div>
    //       </div>
    //     </li>
    //     <li className="you">
    //       <div className="media message d-flex">
    //         <div className="media-left flex-shrink-0 text-center me-3 align-self-start">
    //           <img
    //             src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_04.jpg"
    //             alt=""
    //           />
    //         </div>
    //         <div className="media-body align-self-center flex-grow-1">
    //           <div className="post-comment">
    //             Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //             Cupiditate delectus expedita, exercitationem veniam dolor
    //             similique autem illum incidunt.
    //           </div>
    //           <div className="meta d-flex align-items-center">
    //             <p className="post-date ml-auto">8:20 PM</p>
    //           </div>
    //         </div>
    //       </div>
    //     </li>
    //     <li className="me">
    //       <div className="media message d-flex">
    //         <div className="media-left flex-shrink-0 text-center me-3 align-self-start">
    //           <img
    //             src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_04.jpg"
    //             alt=""
    //           />
    //         </div>
    //         <div className="media-body align-self-center flex-grow-1">
    //           <div className="post-comment">
    //             Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //             Cupiditate delectus expedita, exercitationem veniam dolor
    //             similique autem illum incidunt.
    //           </div>
    //           <div className="meta d-flex align-items-center">
    //             {/* <h6 className="mt-0 mb-0">Elsa Robert</h6> */}
    //             <p className="post-date ml-auto">8:20 PM</p>
    //           </div>
    //         </div>
    //       </div>
    //     </li>
    //     <li className="you">
    //       <div className="media message d-flex">
    //         <div className="media-left flex-shrink-0 text-center me-3 align-self-start">
    //           <img
    //             src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_04.jpg"
    //             alt=""
    //           />
    //         </div>
    //         <div className="media-body align-self-center flex-grow-1">
    //           <div className="post-comment">
    //             Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //             Cupiditate delectus expedita, exercitationem veniam dolor
    //             similique autem illum incidunt.
    //           </div>
    //           <div className="meta d-flex align-items-center">
    //             <p className="post-date ml-auto">8:20 PM</p>
    //           </div>
    //         </div>
    //       </div>
    //     </li>
    //     <li className="me">
    //       <div className="media message d-flex">
    //         <div className="media-left flex-shrink-0 text-center me-3 align-self-start">
    //           <img
    //             src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_04.jpg"
    //             alt=""
    //           />
    //         </div>
    //         <div className="media-body align-self-center flex-grow-1">
    //           <div className="post-comment">
    //             Lorem ipsum dolor sit amet consectetur adipisicing elit.
    //             Cupiditate delectus expedita, exercitationem veniam dolor
    //             similique autem illum incidunt.
    //           </div>
    //           <div className="meta d-flex align-items-center">
    //             {/* <h6 className="mt-0 mb-0">Elsa Robert</h6> */}
    //             <p className="post-date ml-auto">8:20 PM</p>
    //           </div>
    //         </div>
    //       </div>
    //     </li>
    //   </ul>

    //   <footer id="chat-footer">
    //     <div className="message-area">
    //       <textarea type="text" placeholder="Write your message..."></textarea>
    //       <button className="reply-btn ms-auto">
    //         <i className="fa fa-paper-plane" aria-hidden="true"></i>
    //       </button>
    //     </div>
    //   </footer>
    // </main>
  );
}
