import * as yup from "yup";

export const vehicleTypeSchema = yup
  .object({
    name: yup
      .string()
      .required("Vehicle Type Name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    cost: yup.string().required("Vehicle Type Cost is required"),
    status: yup.string().required("Vehicle Type Status is required"),
  })
  .required();

export const settingSchema = yup
  .object({
    adminCommission: yup.string().required("Admin Commission is required"),
    baseFare: yup.string().required("Base Fare Cost is required"),
    kmCost: yup.string().required("Km Cost is required"),
    fragileCost: yup.string().required("Fragile Item Cost is required"),
  })
  .required();
