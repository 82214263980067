import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../Utils/authHeader";

export default function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  let token = getAccessToken();
  useEffect(() => {
    if (token) {
      navigate("/dashboard", { replace: true });
    }
  }, [token]);

  return <>{children}</>;
}
