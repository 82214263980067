import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { verificationSchema } from "../../Schema/authSchema";
import { useState } from "react";
import { useMutation } from "react-query";
import { recoverPassword, verifyCode } from "../../Services/Auth";
import { Error, Success } from "../../Components/Modal";
import Button from "../../Components/Button";

export default function Verification() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(verificationSchema),
  });

  const { mutate, isLoading } = useMutation((data) => verifyCode(data), {
    retry: false,
    onSuccess: (res) => {
      // console.log("Verification Response: ", res.data);
      Success("Verification", res.data?.message);
      navigate("/set-password", { replace: true });
    },
    onError: (err) => {
      // console.log("verifyCode Err: ", err);
      Error(err.response.data?.message);
    },
  });

  const { mutate: mutateResendCode, isLoading: resendCodeLoading } =
    useMutation((data) => recoverPassword(data), {
      retry: false,
      onSuccess: (res) => {
        // console.log("Recover: ", res.data);
        Success("Resend Code", res.data?.message);
      },
      onError: (err) => {
        // console.log("recoverPassword Err: ", err);
        Error(err.response.data?.message);
      },
    });

  const submit = (data) => {
    // console.log(data, "data");
    localStorage.setItem("code", data?.verificationCode);
    mutate({ email: email, otp: data?.verificationCode });
  };

  console.log(errors);
  return (
    <section className="loginPage">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 px-4 loginBgLeft d-none d-lg-block">
            <div className="loginLeftContent mb-3">
              <img
                src="images/loginLogo.png"
                alt=""
                className="img-fluid loginLogo"
              />
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum.{" "}
              </p>
            </div>
            <img src="images/loginLeftImage.png" alt="" className="img-fluid" />
          </div>
          <div className="col-lg-6 loginBgRight">
            <div className="loginCard">
              <div className="text-center d-block d-lg-none">
                <img
                  src="images/loginLogo.png"
                  alt=""
                  className="loginLogo img-fluid mb-2"
                />
              </div>
              <div className="formBox">
                <div className="formHeading my-5">
                  <h2>Password Recovery</h2>
                </div>
                <form
                  // action="password-recovery-3.php"
                  // method="POST"
                  className="py-2"
                  onSubmit={handleSubmit(submit)}
                >
                  <div className="form-field">
                    <label htmlFor="" className="siteLabel">
                      Verification Code<span className="text-danger">*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="number"
                        className="siteInput"
                        placeholder="Enter Verification Code"
                        name=""
                        id=""
                        {...register("verificationCode")}
                      />
                      <span className="text-danger">
                        {errors?.verificationCode?.message}
                      </span>
                    </div>
                  </div>

                  <div className="w-100 text-end">
                    <Button
                      className="forgotLink"
                      onClick={() => mutateResendCode({ email })}
                      style={{ border: 0, backgroundColor: "transparent" }}
                      loading={resendCodeLoading}
                    >
                      Resend Code
                    </Button>
                  </div>
                  <div className="form-field mt-3 mt-lg-5 text-center">
                    <Button
                      type="submit"
                      className="siteBtn w-100 mb-3"
                      loading={isLoading}
                    >
                      Continue
                    </Button>
                    <Link
                      to="/"
                      className="siteBtn2 w-100 mb-3"
                      onClick={() => {
                        localStorage.removeItem("email");
                        localStorage.removeItem("code");
                      }}
                    >
                      Back To Sign In
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
