const Terms = () => {
    return (
        <div className="app-content content dashboard">
            <div className="content-wrapper">
                <div className="content-body">
                    <section id="configuration">
                        <div className="row">
                            <div className="col-12">
                                <h1>SHIPPING &amp; DELIVERY POLICY</h1>
                                <p>
                                    This Shipping &amp; Delivery Policy is part of our ("Terms") and should be
                                    therefore read alongside our main Terms. Please carefully review our
                                    Shipping &amp; Delivery Policy when purchasing our products. This policy
                                    will apply to any order you place with us.
                                </p>
                                <h2>WHAT ARE MY SHIPPING &amp; DELIVERY OPTIONS?</h2>
                                <p>
                                    In-Store Pickup: In-store and curbside pickup is available for Store,
                                    auction, or estate sale location to your door. Pickups are available
                                    scheduled per your request.
                                </p>
                                <p>Shipping Fees: We offer shipping at the following rates:</p>
                                <ul>
                                    <li>Same day within 12 hours: $50</li>
                                    <li>Expedited 1-2 days: $100</li>
                                    <li>Standard: $100</li>
                                    {/* Add more shipping options as needed */}
                                </ul>
                                <p>
                                    All times and dates given for delivery of the products are given in good
                                    faith but are estimates only. Once your driver arrives at the pickup
                                    location, you will receive notification via the method of your choice.
                                </p>
                                <h2>DO YOU DELIVER INTERNATIONALLY?</h2>
                                <p>We do not offer international shipping.</p>
                                <h2>WHAT HAPPENS IF MY ORDER IS DELAYED?</h2>
                                <p>
                                    We understand emergencies happen. If your items cannot be delivered, they
                                    will go to a storage warehouse, inventoried and waiting to be
                                    re-delivered. There is a storage fee of $10.00 per day if no
                                    pre-authorized arrangement has been made. Please contact us via email at{" "}
                                    <a href="mailto:customer.service@muvrrs.com">
                                        customer.service@muvrrs.com
                                    </a>{" "}
                                    to re-schedule.
                                </p>
                                {/* Add more sections as needed */}
                                <h2>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>
                                <p>If you have any further questions or comments, you may contact us by:</p>
                                <ul>
                                    <li>
                                        Email:{" "}
                                        <a href="mailto:customer.service@muvrrs.com">
                                            customer.service@muvrrs.com
                                        </a>
                                    </li>
                                    <li>
                                        Online contact form:{" "}
                                        <a href="http://www.muvrrs.com/customer.service">
                                            http://www.muvrrs.com/customer.service
                                        </a>
                                    </li>
                                </ul>
                                <h1>ACCEPTABLE USE POLICY</h1>
                                <p>
                                    This Acceptable Use Policy ("Policy") is part of our Terms and Conditions
                                    ("Legal Terms") and should therefore be read alongside our main Legal
                                    Terms:
                                    <a href="http://www.muvrrs.com">http://www.muvrrs.com</a>. If you do not
                                    agree with these Legal Terms, please refrain from using our Services. Your
                                    continued use of our Services implies acceptance of these Legal Terms.
                                    Please carefully review this Policy which applies to any and all:
                                </p>
                                <ol>
                                    <li>uses of our Services (as defined in "Legal Terms")</li>
                                    <li>
                                        forms, materials, consent tools, comments, post, and all other content
                                        available on the Services ("Content")
                                    </li>
                                    <li>
                                        material which you contribute to the Services including any upload,
                                        post, review, disclosure, ratings, comments, chat etc. in any forum,
                                        chatrooms, reviews, and to any interactive services associated with it
                                        ("Contribution")
                                    </li>
                                </ol>
                                <h2>WHO WE ARE</h2>
                                <p>
                                    We are MUVRRS LLC ("Company," "we," "us," or "our") a company registered
                                    in New York, United States at 7700 Goodrich Rd, Clarence Center, NY 14032.
                                    We operate the website <a href="https://muvrrs.com">https://muvrrs.com</a>{" "}
                                    (the "Site"), the mobile application MUVRRS (the "App"), as well as any
                                    other related products and services that refer or link to this Policy
                                    (collectively, the "Services").
                                </p>
                                <h2>USE OF THE SERVICES</h2>
                                <p>
                                    When you use the Services you warrant that you will comply with this
                                    Policy and with all applicable laws. You also acknowledge that you may
                                    not:
                                </p>
                                {/* List of prohibited actions */}
                                <ul>
                                    <li>
                                        Systematically retrieve data or other content from the Services...
                                    </li>
                                    <li>Make any unauthorized use of the Services...</li>
                                    <li>
                                        Circumvent, disable, or otherwise interfere with security-related
                                        features of the Services...
                                    </li>
                                    {/* Add more prohibited actions as needed */}
                                </ul>
                                {/* Add more sections as needed */}
                                <h2>CONTRIBUTIONS</h2>
                                <p>
                                    In this Policy, the term "Contributions" means: any data, information,
                                    software, text, code, music, scripts, sound, graphics, photos, videos,
                                    tags, messages, interactive features, or other materials that you post,
                                    share, upload, submit, or otherwise provide in any manner on or through to
                                    the Services; or any other content, materials, or data you provide to
                                    MUVRRS LLC or use with the Services.
                                </p>
                                <p>
                                    Some areas of the Services may allow users to upload, transmit, or post
                                    Contributions. We may but are under no obligation to review or moderate
                                    the Contributions made on the Services, and we expressly exclude our
                                    liability for any loss or damage resulting from any of our users' breach
                                    of this Policy.
                                </p>
                                {/* Add more sections as needed */}
                                <h2>REPORTING A BREACH OF THIS POLICY</h2>
                                <p>
                                    We may but are under no obligation to review or moderate the Contributions
                                    made on the Services and we expressly exclude our liability for any loss
                                    or damage resulting from any of our users' breach of this Policy.
                                </p>
                                {/* Add more sections as needed */}
                                <h2>CONSEQUENCES OF BREACHING THIS POLICY</h2>
                                <p>
                                    The consequences for violating our Policy will vary depending on the
                                    severity of the breach and the user's history on the Services, by way of
                                    example:
                                </p>
                                {/* Add more sections as needed */}
                                <h2>COMPLAINTS AND REMOVAL OF LEGITIMATE CONTENT</h2>
                                <p>
                                    If you consider that some Content or Contribution have been mistakenly
                                    removed or blocked from the Services, please refer to the contact details
                                    at the bottom of this document and we will promptly review our decision to
                                    remove such Content or Contribution. The Content or Contribution may stay
                                    "down" whilst we conduct the review process.
                                </p>
                                {/* Add more sections as needed */}
                                <h2>DISCLAIMER</h2>
                                <p>
                                    MUVRRS LLC is under no obligation to monitor users’ activities, and we
                                    disclaim any responsibility for any user’s misuse of the Services. MUVRRS
                                    LLC has no responsibility for any user or other Content or Contribution
                                    created, maintained, stored, transmitted, or accessible on or through the
                                    Services, and is not obligated to monitor or exercise any editorial
                                    control over such material. If MUVRRS LLC becomes aware that any such
                                    Content or Contribution violates this Policy, MUVRRS LLC may, in addition
                                    to removing such Content or Contribution and blocking your account, report
                                    such breach to the police or appropriate regulatory authority. Unless
                                    otherwise stated in this Policy, MUVRRS LLC disclaims any obligation to
                                    any person who has not entered into an agreement with MUVRRS LLC for the
                                    use of the Services.
                                </p>
                                {/* Add more sections as needed */}
                                <h2>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h2>
                                <p>
                                    If you have any further questions or comments or wish to report any
                                    problematic Content or Contribution, you may contact us by:
                                </p>
                                <ul>
                                    <li>Phone: 5855427226</li>
                                    <li>
                                        Email:{" "}
                                        <a href="mailto:customer.service@muvrrs.com">
                                            customer.service@muvrrs.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    )
}

export default Terms