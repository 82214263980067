import moment from "moment/moment";
import { image_url } from "./connection_strings";

export const asset = (filePath = null) => {
  return `${image_url}${filePath}`;
};

export const format_date = (date, format = "LL") => moment(date).format(format);

export const format_time = (time, format = "hh:mm A") =>
  moment(time, "HH:mm:ss").format(format);

export const custom_format_time = (
  time,
  givenFormat = "hh:mm a",
  format = "hh:mm A"
) => moment(time, givenFormat).format(format);

export const convert_utcToLocal = (
  date,
  givenFormat = "",
  outputFormat = ""
) => {
  try {
    const convertedUTC = moment
      .utc(date, givenFormat)
      .local()
      .format(outputFormat);
    return convertedUTC;
  } catch (e) {
    console.log("convert_utcToLocal Error: ", e);
  }
};

export const convert_time_utcToLocal = (time) => {
  try {
    const convertedTime = moment.utc(time, "hh:mm a").local().format("hh:mm A");
    return convertedTime;
  } catch (e) {
    console.log("convert_time_utcToLocal Error: ", e);
  }
};

export const convert_time_localToUTC = (time) => {
  try {
    const convertedUTCTime = moment(time, "hh:mm a").utc().format("hh:mm a");
    return convertedUTCTime;
  } catch (e) {
    console.log("convert_time_localToUTC Error: ", e);
  }
};

export const numberFormat = (number) => {
  const SI_POSTFIXES = ["", "K", "M", "G", "T", "P", "E"];
  const sign = number < 0 ? "-1" : "";
  const absNumber = Math.abs(number);
  const tier = (Math.log10(absNumber) / 3) | 0;
  // if zero, we don't need a prefix
  if (tier == 0) return `${absNumber}`;
  // get postfix and determine scale
  const postfix = SI_POSTFIXES[tier];
  const scale = Math.pow(10, tier * 3);
  // scale the number
  const scaled = absNumber / scale;
  const floored = Math.floor(scaled * 10) / 10;
  // format number and add postfix as suffix
  let str = floored.toFixed(1);
  // remove '.0' case
  str = /\.0$/.test(str) ? str.substr(0, str.length - 2) : str;
  return `${sign}${str}${postfix}`;
};

export const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
};

export const createDateAsUTC = (date) => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};

export const getSerialNumber = (data, index) => {
  const serialIndex = (data.page - 1) * data.limit + index + 1;
  return serialIndex;
};

export function capitalizeFirstLetter(string) {
  return string?.[0]?.toUpperCase() + string?.slice(1);
}

export const numberIsNegative = (num) => {
  if (!isNaN(num)) {
    return Math.sign(num) == -1 ? true : false;
  }
  return false;
};

export function filterParams(params) {
  const filteredParams = {};
  for (const key in params) {
    const value = params[key];
    if (value !== null && value !== undefined && value !== "") {
      filteredParams[key] = value;
    }
  }
  return filteredParams;
}

export function getMongoUTCDate() {
  return moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
}
