import { setAccessToken } from "../../Utils/authHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../Schema/authSchema";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useMutation } from "react-query";
import { login } from "../../Services/Auth";
import { setUser } from "../../Redux/slice";
import Button from "../../Components/Button";
import { Error } from "../../Components/Modal";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const [showPassword, setShowPassword] = useState(false);

  const { mutate, isLoading } = useMutation((data) => login(data), {
    retry: false,
    onSuccess: (res) => {
      // console.log("Login: ", res.data);
      setAccessToken(res.data?.token);
      dispatch(setUser(res.data?.user));
      navigate("/dashboard");
    },
    onError: (err) => {
      // console.log("Login Err: ", err.response.data);
      Error(err.response.data?.message);
    },
  });

  const submit = (data) => {
    // console.log({ data });
    const loginObj = {
      email: data?.email,
      password: data?.password,
      source: "admin",
    };
    mutate(loginObj);
  };

  return (
    <section className="loginPage">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 px-4 loginBgLeft d-none d-lg-block">
            <div className="loginLeftContent text-center mb-3">
              <img
                src="images/loginLogo.png"
                alt=""
                className="img-fluid loginLogo"
              />
            </div>
          </div>
          <div className="col-lg-6 loginBgRight">
            <div className="loginCard">
              <div className="text-center d-block d-lg-none">
                <img
                  src="images/loginLogo.png"
                  alt=""
                  className="loginLogo img-fluid mb-2"
                />
              </div>
              <div className="formBox">
                <div className="formHeading my-5">
                  <h2>Sign In</h2>
                </div>
                <form
                  // action="../dashboard/dashboard.php"
                  // method="POST"
                  className="py-2"
                  onSubmit={handleSubmit(submit)}
                >
                  <div className="form-field">
                    <label htmlFor="" className="siteLabel">
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="email"
                        className="siteInput"
                        placeholder="Enter Email Address"
                        name=""
                        id=""
                        {...register("email")}
                      />
                      <span className="text-danger">
                        {errors?.email?.message}
                      </span>
                    </div>
                  </div>
                  <div className="form-field">
                    <label htmlFor="" className="siteLabel">
                      Password<span className="text-danger">*</span>
                    </label>
                    <div className="passwordWrapper position-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="siteInput passInput"
                        placeholder="Enter Password"
                        name=""
                        id=""
                        {...register("password")}
                      />
                      <button
                        type="button"
                        className="passDisplay"
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        <i
                          className={
                            showPassword ? "fas fa-eye" : "fas fa-eye-slash"
                          }
                          aria-hidden="true"
                        />
                      </button>
                      <span className="text-danger">
                        {errors?.password?.message}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="w-100">
                      <input type="checkbox" id="vehicle1" />
                      <label className="ps-4" for="vehicle1">
                        Remember Me
                      </label>
                    </div>
                    <div className="w-100 text-end">
                      <Link to="/forgot-password" className="forgotLink">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="form-field mt-3 mt-lg-5 text-center">
                    <Button
                      type="submit"
                      className="siteBtn w-100 mb-3"
                      loading={isLoading}
                    >
                      Sign In
                    </Button>
                    {/* <Link to="/" className="siteBtn2 w-100 mb-3">
                      Back To Website
                    </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
