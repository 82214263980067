import api from "../Utils/axios";

export const getProfile = () => {
  return api.get(`/user/getProfile`);
};

export const updateProfile = (data) => {
  return api.post(`/user/editProfile`, data);
};

export const changePassword = (data) => {
  return api.post(`/user/changePass`, data);
};
