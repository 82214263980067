import api from "../Utils/axios";
import { filterParams } from "../Utils/helpers";

export const getConversations = (role) => {
  return api.get(`/admin-chat/conversations`, {
    params: filterParams({
      role,
    }),
  });
};

export const getConversationMessages = (conversationId) => {
  return api.get(`/admin-chat/messages/${conversationId}`);
};

export const sendMessage = (data) => {
  return api.post("/admin-chat/sendMessage", data);
};
