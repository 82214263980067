import { FaArrowLeft } from "react-icons/fa"
import { useNavigate } from 'react-router-dom'

const BackButton = () => {

    const navigate = useNavigate()

    return (
        <FaArrowLeft onClick={() => navigate(-1)} className="cursor-pointer me-2" />
    )

}

export default BackButton