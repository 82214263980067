import * as yup from "yup";

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),

    password: yup.string().required("Password is required"),
  })
  .required();

export const forgetPasswordSchema = yup
  .object({
    email: yup
      .string()
      .email("Field should contain a valid e-mail")
      .max(255)
      .required("E-mail is required"),
  })
  .required();

export const verificationSchema = yup
  .object({
    verificationCode: yup.string().required("Verification code is required"),
  })
  .required();

export const setPasswordSchema = yup
  .object({
    newPassword: yup.string().max(20).required("New password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords doesn't match"),
  })
  .required();
