import { format_date } from "../../Utils/helpers";

export default function DriverDetail({ data }) {
  return (
    <div
      className="modal fade"
      id="driverDetails"
      data-bs-backdrop="static"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
    >
      <div
        className="modal-dialog modal-dialog-centered site-modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modalIndex">
            <i
              className="fas fa-times close modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="w-100">
              <div className="text-center mb-5">
                <h3 className="sectionTitle">Driver Details</h3>
              </div>
              <div className="text-center my-3">
                <div className="userIimageFrame mx-auto">
                  <img
                    src={
                      data?.profileImage?.imageUrl ?? "images/avatar.png"
                    }
                    alt=""
                    className="userImage img-fluid"
                  />
                </div>
              </div>
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Full Name:
                </label>
                <p className="mainText">{data?.firstName}</p>
              </div>
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Email Address:
                </label>
                <p className="mainText">{data?.email}</p>
              </div>
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Phone Number:
                </label>
                <p className="mainText">
                  {data?.phone && data?.countryCode
                    ? data?.countryCode + "-" + data?.phone
                    : data?.phone}
                </p>
              </div>
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Date Registered:
                </label>
                <p className="mainText">
                  {data?.createdAt &&
                    format_date(data?.createdAt, "DD/MM/YYYY")}
                </p>
              </div>
              <div className="mainDetail d-md-flex align-items-end my-3">
                <label htmlFor="" className="mainLabel mainLabel-lg">
                  Number of Deliveries Completed Successfully:
                </label>
                <p className="mainText"></p>
              </div>
              {(data?.licenseImageFront?.imageUrl ||
                data?.licenseImageBack?.imageUrl) && (
                <div className="mainDetail d-md-flex align-items-start my-3">
                  <label htmlFor="" className="mainLabel mainLabel-lg">
                    License:
                  </label>
                  <img
                    src={
                      data?.licenseImageFront?.imageUrl ||
                      data?.licenseImageBack?.imageUrl
                    }
                    // src="images/license.png"
                    alt=""
                    className="mainImage img-fluid"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
