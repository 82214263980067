import { useParams } from "react-router-dom";
import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import { useQuery } from "react-query";
import { getUserDetails } from "../../Services/User";
import { getAllRides } from "../../Services/Ride";
import { useEffect, useState } from "react";
import useTableControls from "../../Hooks/useTableControls";
import useFetchData from "../../Hooks/useFetchData";
import { format_date, getSerialNumber } from "../../Utils/helpers";
import { FaArrowLeft } from 'react-icons/fa'
import BackButton from "../../Components/BackButton";

export default function UserDetails() {
  // const sampleData = [
  //   {
  //     sn: "1",
  //     bookingId: "123",
  //     moverName: "Mark Carson",
  //     date: "01/02/2022",
  //     pickupLocation: "Abc Location",
  //     dropoffLocation: "Abc Location",
  //     status: "pending",
  //   },
  //   {
  //     sn: "2",
  //     bookingId: "123",
  //     moverName: "Mark Carson",
  //     date: "01/02/2022",
  //     pickupLocation: "Abc Location",
  //     dropoffLocation: "Abc Location",
  //     status: "accepted",
  //   },
  //   {
  //     sn: "3",
  //     bookingId: "123",
  //     moverName: "Mark Carson",
  //     date: "01/02/2022",
  //     pickupLocation: "Abc Location",
  //     dropoffLocation: "Abc Location",
  //     status: "completed",
  //   },
  //   {
  //     sn: "4",
  //     bookingId: "123",
  //     moverName: "Mark Carson",
  //     date: "01/02/2022",
  //     pickupLocation: "Abc Location",
  //     dropoffLocation: "Abc Location",
  //     status: "cancelled",
  //   },
  // ];

  const { id } = useParams();
  const [user, setUser] = useState({});
  const {
    perPage,
    setPerPage,
    status,
    setStatus,
    search_string,
    setSearchString,
    from,
    setFrom,
    to,
    setTo,
  } = useTableControls();

  const { data, isLoading, refetch } = useQuery(["user_details", id], () =>
    getUserDetails(id)
  );
  // console.log("user_details: ", data?.data);

  useEffect(() => {
    if (data?.data?.data?.docs?.length > 0)
      setUser(data?.data?.data?.docs?.[0]);
  }, [data?.data?.data?.docs]);

  const {
    // INTERNAL EXPORTS
    setPage,
    // REACT QUERY EXPORTS
    isFetching: rideFetching,
    isLoading: rideLoading,
    data: rideData,
    refetch: rideRefetch,
  } = useFetchData("user_ride_logs", getAllRides, [
    perPage,
    search_string,
    status,
    from,
    to,
    id,
  ]);
  // console.log("user_ride_logs Data: ", rideData?.data);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="back-title">
                        <BackButton />
                        <div className="pageTitleInner">
                          <h1 className="pageTitle text-capitalize m-0">
                            User Details
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="row dashCard2 py-3 px-4">
                        <div className="col-12">
                          <div className="text-end">
                            <div className="statusBox">
                              <p className="m-0">
                                Status:{" "}
                                <span>
                                  {user?.active === true
                                    ? "Active"
                                    : user?.active === false && "Inactive"}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3">
                          <div className="userIimageFrame my-2 mx-xl-auto">
                            <img
                              src={
                                user?.profileImage?.imageUrl ??
                                "images/avatar.png"
                              }
                              alt=""
                              className="userImage img-fluid"
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        </div>
                        <div className="col-xl-9">
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Full Name:
                            </label>
                            <p className="mainText">{user?.firstName}</p>
                          </div>
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Email Address:
                            </label>
                            <p className="mainText">{user?.email}</p>
                          </div>
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Total Bookings:
                            </label>
                            <p className="mainText">
                              {rideData?.data?.data?.totalDocs}
                            </p>
                          </div>
                          <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              Phone Number:
                            </label>
                            <p className="mainText">{user?.phone}</p>
                          </div>
                          {/* <div className="mainDetail d-lg-flex align-items-baseline my-1">
                            <label htmlFor="" className="mainLabel">
                              SSN:
                            </label>
                            <p className="mainText"></p>
                            <p className="mainText">000-000-0000</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Table
                    key={"user-details-rides-table"}
                    tableTitles={[
                      "S.No.",
                      "Booking ID",
                      "Muvrr's Name",
                      "Date",
                      "Pickup Location",
                      "Drop-Off Location",
                      "Amount Earned",
                      "Status",
                      "Action",
                    ]}
                    dateFilter
                    showEntries
                    sortBy
                    isLoading={rideLoading}
                    isFetching={rideFetching}
                    data={rideData?.data?.data?.docs}
                    totalPages={rideData?.data?.data?.totalPages}
                    totalDocs={rideData?.data?.data?.totalDocs}
                    setPage={setPage}
                    setSearchString={setSearchString}
                    status={status}
                    setStatus={setStatus}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    status_options={[
                      {
                        label: "Pending",
                        value: "pending",
                      },
                      {
                        label: "Accepted",
                        value: "accepted",
                      },
                      {
                        label: "Rejected",
                        value: "rejected",
                      },
                      {
                        label: "Started",
                        value: "started",
                      },
                      {
                        label: "Completed",
                        value: "completed",
                      },
                      {
                        label: "Cancelled",
                        value: "cancelled",
                      },
                    ]}
                  >
                    {!rideFetching &&
                      rideData?.data?.data?.docs?.map((item, index) => (
                        <tr>
                          <td>
                            {getSerialNumber(rideData?.data?.data, index)}
                          </td>
                          <td>{item?._id}</td>
                          <td>{item?.driver?.firstName}</td>
                          <td>
                            {item?.createdAt &&
                              format_date(item?.createdAt, "MM/DD/YYYY")}
                          </td>
                          <td>{item?.bookingDetails?.pickupLocation?.name}</td>
                          <td>{item?.bookingDetails?.dropOffLocation?.name}</td>
                          <td>{item?.bookingDetails?.adminEarnedAmount &&
                            `$ ${item?.bookingDetails?.adminEarnedAmount?.toFixed(
                              2
                            )}`}
                          </td>
                          <td>{item.status}</td>
                          <td>
                            <StatusHandler
                              key={`${item?._id}-status`}
                              view
                              item={item}
                              redirectUrl={`/ride-management/ride-details/${item?._id}`}
                            />
                          </td>
                        </tr>
                      ))}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
