import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../Pages/Auth/Login";
import ForgetPassword from "../Pages/Auth/ForgotPassword";
import Verification from "../Pages/Auth/Verification";
import SetPassword from "../Pages/Auth/SetPassword";
import Profile from "../Pages/Profile";
import EditProfile from "../Pages/Profile/EditProfile";
import ChangePassword from "../Pages/Profile/ChangePassword";
import Notifications from "../Pages/Notifications";
import Dashboard from "../Pages/Dashboard";
import UserManagement from "../Pages/UserManagement";
import UserDetails from "../Pages/UserManagement/UserDetails";
import MoverManagement from "../Pages/MoverManagement";
import MoverDetails from "../Pages/MoverManagement/MoverDetails";
import SignupRequests from "../Pages/MoverManagement/SignupRequests";
import SignupRequestDetails from "../Pages/MoverManagement/SignupRequestDetails";
import VehicleRequests from "../Pages/MoverManagement/VehicleRequests";
import VehicleRequestDetails from "../Pages/MoverManagement/VehicleRequestDetails";
import EditVehicleDetails from "../Pages/MoverManagement/EditVehicleDetails";
import RidesManagement from "../Pages/RidesManagement";
import RideDetails from "../Pages/RidesManagement/RideDetails";
import Earnings from "../Pages/Earnings";
import EarningDetails from "../Pages/Earnings/EarningDetails";
import Feedbacks from "../Pages/Feedbacks";
import FeedbackDetails from "../Pages/Feedbacks/FeedbackDetails";
import VehicleTypeManagement from "../Pages/VehicleTypeManagement";
import AddVehicleType from "../Pages/VehicleTypeManagement/AddVehicleType";
import EditVehicleType from "../Pages/VehicleTypeManagement/EditVehicleType";
import PaymentLogs from "../Pages/PaymentLogs";
import Settings from "../Pages/Settings";
import EditSetting from "../Pages/Settings/EditSetting";
import Chat from "../Pages/Chat";
import PageNotFound from "../Pages/PageNotFound";
import Reports from "../Pages/Reports";
import ReportDetails from "../Pages/Reports/ReportDetails";
import Aboutus from "../Pages/Content/Aboutus";
import Contactus from "../Pages/Content/Contactus";
import PrivacyPolicy from "../Pages/Content/PrivacyPolicy";
import Terms from "../Pages/Content/Terms";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><Login /></ProtectedRoute>} />
      <Route path="/forgot-password" element={<ProtectedRoute><ForgetPassword /></ProtectedRoute>} />
      <Route path="/verification" element={<ProtectedRoute><Verification /></ProtectedRoute>} />
      <Route path="/set-password" element={<ProtectedRoute><SetPassword /></ProtectedRoute>} />

      <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="/profile/edit" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
      <Route path="/profile/change-password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
      <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
      <Route path="/chat" element={<PrivateRoute><Chat /></PrivateRoute>} />

      <Route path="/vehicle-type-management" element={<PrivateRoute><VehicleTypeManagement /></PrivateRoute>} />
      <Route path="/vehicle-type-management/add" element={<PrivateRoute><AddVehicleType /></PrivateRoute>} />
      <Route path="/vehicle-type-management/edit/:id" element={<PrivateRoute><EditVehicleType /></PrivateRoute>} />
      <Route path="/payment-logs" element={<PrivateRoute><PaymentLogs /></PrivateRoute>} />
      <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
      <Route path="/settings/edit" element={<PrivateRoute><EditSetting /></PrivateRoute>} />

      <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path="/user-management" element={<PrivateRoute><UserManagement /></PrivateRoute>} />
      <Route path="/user-management/user-details/:id" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
      <Route path="/mover-management" element={<PrivateRoute><MoverManagement /></PrivateRoute>} />
      <Route path="/mover-management/mover-details/:id" element={<PrivateRoute><MoverDetails /></PrivateRoute>} />
      <Route path="/mover-management/signup-requests" element={<PrivateRoute><SignupRequests /></PrivateRoute>} />
      <Route path="/mover-management/signup-requests/:id" element={<PrivateRoute><SignupRequestDetails /></PrivateRoute>} />
      <Route path="/mover-management/vehicle-requests" element={<PrivateRoute><VehicleRequests /></PrivateRoute>} />
      <Route path="/mover-management/vehicle-requests/:id" element={<PrivateRoute><VehicleRequestDetails /></PrivateRoute>} />
      <Route path="/mover-management/mover-details/:id/vehicle-details/:vehicleId" element={<PrivateRoute><EditVehicleDetails /></PrivateRoute>} />
      <Route path="/ride-management" element={<PrivateRoute><RidesManagement /></PrivateRoute>} />
      <Route path="/ride-management/ride-details/:id" element={<PrivateRoute><RideDetails /></PrivateRoute>} />
      <Route path="/earnings" element={<PrivateRoute><Earnings /></PrivateRoute>} />
      <Route path="/earnings/earning-details/:id" element={<PrivateRoute><EarningDetails /></PrivateRoute>} />
      <Route path="/feedbacks" element={<PrivateRoute><Feedbacks /></PrivateRoute>} />
      <Route path="/feedbacks/feedback-details/:id" element={<PrivateRoute><FeedbackDetails /></PrivateRoute>} />
      <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
      <Route path="/reports/reports-details/:id" element={<PrivateRoute><ReportDetails /></PrivateRoute>} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/about-us" element={<Aboutus />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-condition" element={<Terms />} />
      <Route path="/contact-us" element={<Contactus />} />
    </Routes>
  );
}
