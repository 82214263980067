export let connection_string;
export let image_url;
export let socket_url;

const PORT = 5000;
const LIVE_PORT = 5000;

// if (window.location.hostname == "localhost") {
//   // connection_string = `http://localhost:${PORT}/apis`;
//   // image_url = `http://localhost:${PORT}`;
//   // socket_url = `http://localhost:${PORT}`;
//   connection_string = `https://mobileapps.onlinetestingserver.com:${PORT}/apis`;
//   image_url = `https://mobileapps.onlinetestingserver.com:${PORT}`;
//   socket_url = `https://mobileapps.onlinetestingserver.com:${PORT}`;
// } else {
connection_string = `https://muvrrs.org:${LIVE_PORT}/apis`;
image_url = `https://muvrrs.org:${LIVE_PORT}`;
socket_url = `https://muvrrs.org:${LIVE_PORT}`;
// }
