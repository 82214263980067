import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import { Confirmation, Error, Success } from "../../Components/Modal";
import useTableControls from "../../Hooks/useTableControls";
import useFetchData from "../../Hooks/useFetchData";
import { changeUserStatus, getAllUsers } from "../../Services/User";
import { useMutation } from "react-query";
import { format_date, getSerialNumber } from "../../Utils/helpers";

export default function UserManagement() {
  const {
    perPage,
    setPerPage,
    status,
    setStatus,
    search_string,
    setSearchString,
    from,
    setFrom,
    to,
    setTo,
  } = useTableControls();

  const {
    // INTERNAL EXPORTS
    setPage,
    // REACT QUERY EXPORTS
    isFetching,
    isLoading,
    data,
    refetch,
  } = useFetchData("user_logs", getAllUsers, [
    perPage,
    "user",
    search_string,
    status,
    from,
    to,
  ]);
  // console.log("user_logs Data: ", data?.data);

  const { mutate, isLoading: loadingStatus } = useMutation(
    (id) => changeUserStatus(id),
    {
      onSuccess: (res) => {
        refetch();
        Success("User Status", res.data?.message);
      },
      onError: (err) => {
        Error(err?.response?.data?.message);
      },
    }
  );

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <Table
                    key={"user-management-table"}
                    showTableName
                    tableName={"Users Management"}
                    tableTitles={[
                      "S.No.",
                      "Full Name",
                      "Email Address",
                      "Date Registered",
                      "Status",
                      "Action",
                    ]}
                    dateFilter
                    showEntries
                    sortBy
                    isLoading={isLoading}
                    isFetching={isFetching}
                    data={data?.data?.data?.docs}
                    totalPages={data?.data?.data?.totalPages}
                    totalDocs={data?.data?.data?.totalDocs}
                    setPage={setPage}
                    setSearchString={setSearchString}
                    status={status}
                    setStatus={setStatus}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                  >
                    {!isFetching &&
                      data?.data?.data?.docs?.map((item, index) => (
                        <tr>
                          <td>{getSerialNumber(data?.data?.data, index)}</td>
                          <td>{item.firstName}</td>
                          <td>{item.email}</td>
                          <td>
                            {item?.createdAt &&
                              format_date(item?.createdAt, "MM/DD/YYYY")}
                          </td>
                          <td>{item.active ? "Active" : "Inactive"}</td>
                          <td>
                            <StatusHandler
                              key={`${item?._id}-status`}
                              view
                              active
                              item={item}
                              isLoading={loadingStatus}
                              redirectUrl={`/user-management/user-details/${item?._id}`}
                              onClick={() =>
                                Confirmation(
                                  `Are you sure you want to ${
                                    item?.active ? "inactive" : "active"
                                  }  ${item.firstName}`,
                                  "Yes",
                                  () => mutate(item?._id)
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </Table>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="inactiveUser"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/question.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Are you sure you want to In-Active Mark Carson?
                          </p>
                        </div>
                      </div>
                      <div className="modalAction">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="siteBtn2 mx-1"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="siteBtn mx-1"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="activeUser"
              data-bs-backdrop="static"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
            >
              <div
                className="modal-dialog modal-dialog-centered site-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modalIndex">
                    <i
                      className="fas fa-times close modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                    <div className="text-center">
                      <div className="modal-text-content mb-1">
                        <div className="modalImg">
                          <img
                            src="images/question.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="modal-text-content my-4">
                          <p className="modalText">
                            Are you sure you want to Active Mark Carson?
                          </p>
                        </div>
                      </div>
                      <div className="modalAction">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="siteBtn2 mx-1"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="siteBtn mx-1"
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
