import { Link, useNavigate } from "react-router-dom";
import { Error, Success } from "../../Components/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordSchema } from "../../Schema/profileSchema";
import { changePassword } from "../../Services/Profile";
import { useMutation } from "react-query";
import Button from "../../Components/Button";
import { useState } from "react";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const { mutate, isLoading } = useMutation((data) => changePassword(data), {
    retry: false,
    onSuccess: (res) => {
      Success("Change Password", res.data?.message);
      navigate("/profile");
    },
    onError: (err) => {
      // console.log("changePassword Err: ", err);
      Error(err.response.data?.message);
    },
  });

  const submit = (data) => {
    console.log(data, "data");
    mutate({
      currentPassword: data?.password,
      newPassword: data?.confirmPassword,
    });
  };

  console.log(errors);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-center">
                          <Link to="/profile" className="forgotLink pe-3">
                            <i class="fas fa-arrow-left"></i>
                          </Link>
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                            Change Password
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-xxl-6">
                          <form
                            //  action="profile.php"
                            onSubmit={handleSubmit(submit)}
                          >
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Existing Password
                                <span className="text-danger">*</span>
                              </label>
                              <div className="passwordWrapper position-relative">
                                <input
                                  type={
                                    showPassword.currentPassword
                                      ? "text"
                                      : "password"
                                  }
                                  className="mainInput passInput dashInput"
                                  placeholder="Enter Existing Password"
                                  name=""
                                  {...register("password")}
                                />
                                <button
                                  type="button"
                                  className="passDisplay"
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      currentPassword:
                                        !showPassword.currentPassword,
                                    })
                                  }
                                >
                                  <i
                                    className={
                                      showPassword.currentPassword
                                        ? "fas fa-eye"
                                        : "fas fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  />
                                </button>
                                <span className="text-danger">
                                  {errors?.password?.message}
                                </span>
                              </div>
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                New Password
                                <span className="text-danger">*</span>
                              </label>
                              <div className="passwordWrapper position-relative">
                                <input
                                  type={
                                    showPassword.newPassword
                                      ? "text"
                                      : "password"
                                  }
                                  className="mainInput passInput dashInput"
                                  placeholder="Enter New Password"
                                  name=""
                                  {...register("newPassword")}
                                />
                                <button
                                  type="button"
                                  className="passDisplay"
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      newPassword: !showPassword.newPassword,
                                    })
                                  }
                                >
                                  <i
                                    className={
                                      showPassword.newPassword
                                        ? "fas fa-eye"
                                        : "fas fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  />
                                </button>
                                <span className="text-danger">
                                  {errors?.newPassword?.message}
                                </span>
                              </div>
                            </div>
                            <div className="mainDetail my-3">
                              <label htmlFor="" className="mainLabel">
                                Confirm Password
                                <span className="text-danger">*</span>
                              </label>
                              <div className="passwordWrapper position-relative">
                                <input
                                  type={
                                    showPassword.confirmPassword
                                      ? "text"
                                      : "password"
                                  }
                                  className="mainInput passInput dashInput"
                                  placeholder="Confirm Password"
                                  name=""
                                  {...register("confirmPassword")}
                                />
                                <button
                                  type="button"
                                  className="passDisplay"
                                  onClick={() =>
                                    setShowPassword({
                                      ...showPassword,
                                      confirmPassword:
                                        !showPassword.confirmPassword,
                                    })
                                  }
                                >
                                  <i
                                    className={
                                      showPassword.confirmPassword
                                        ? "fas fa-eye"
                                        : "fas fa-eye-slash"
                                    }
                                    aria-hidden="true"
                                  />
                                </button>
                                <span className="text-danger">
                                  {errors?.confirmPassword?.message}
                                </span>
                              </div>
                            </div>
                            <div className="mainDetail text-end my-3">
                              <Button
                                type="submit"
                                className="siteBtn"
                                loading={isLoading}
                              >
                                Update
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
