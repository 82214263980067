import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../Utils/authHeader";
import Layout from "../Layout";

export default function PrivateRoute({ children }) {
  const navigate = useNavigate();
  let token = getAccessToken();
  useEffect(() => {
    if (!token) {
      navigate("/", { replace: true });
    }
  }, [token]);

  return (
    <>
      <Layout>{children}</Layout>
    </>
  );
}
