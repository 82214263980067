import api from "../Utils/axios";
import { filterParams } from "../Utils/helpers";

export const getFeedbacks = (page, perPage, search_string, from, to) => {
  return api.get(`/feedback/get`, {
    params: filterParams({
      page,
      limit: perPage,
      keyword: search_string,
      "createdAt[from]": from,
      "createdAt[to]": to,
    }),
  });
};

export const getFeedbackDetails = (id) => {
  return api.get(`/feedback/get`, {
    params: {
      _id: id,
    },
  });
};
