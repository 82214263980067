import { useNavigate } from "react-router-dom";
import { Confirmation, Reason } from "../../Components/Modal";
import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import useTableControls from "../../Hooks/useTableControls";
import useFetchData from "../../Hooks/useFetchData";
import { getSignupRequests } from "../../Services/SignupRequest";
import { format_date, getSerialNumber } from "../../Utils/helpers";

export default function SignupRequests() {
  const navigate = useNavigate();

  // const sampleData = [
  //   {
  //     sn: "1",
  //     name: "Mark Carson",
  //     email: "abc@xyz.com",
  //     date: "01/02/2022",
  //     phone: "+1 12 213 0",
  //     requestApprovalStatus: "pending",
  //   },
  //   {
  //     sn: "2",
  //     name: "Mark Carson",
  //     email: "abc@xyz.com",
  //     date: "01/02/2022",
  //     phone: "+1 12 213 0",
  //     requestApprovalStatus: "accepted",
  //   },
  //   {
  //     sn: "3",
  //     name: "Mark Carson",
  //     email: "abc@xyz.com",
  //     date: "01/02/2022",
  //     phone: "+1 12 213 0",
  //     requestApprovalStatus: "pending",
  //   },
  //   {
  //     sn: "4",
  //     name: "Mark Carson",
  //     email: "abc@xyz.com",
  //     date: "01/02/2022",
  //     phone: "+1 12 213 0",
  //     requestApprovalStatus: "pending",
  //   },
  // ];

  const {
    perPage,
    setPerPage,
    status,
    setStatus,
    search_string,
    setSearchString,
    from,
    setFrom,
    to,
    setTo,
  } = useTableControls();

  const {
    // INTERNAL EXPORTS
    setPage,
    // REACT QUERY EXPORTS
    isFetching,
    isLoading,
    data,
    refetch,
  } = useFetchData("signupRequest_logs", getSignupRequests, [
    perPage,
    search_string,
    "pending",
    from,
    to,
  ]);
  // console.log("signupRequest_logs Data: ", data?.data);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0">
                            Muvrrs Management
                          </h1>
                          <span className="mx-2">
                            <button
                              type="button"
                              className="backLink mr-1"
                              onClick={() => navigate(-1)}
                            >
                              <i className="fas fa-chevron-left" />
                            </button>
                            Signup Request
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Table
                    key={"mover-management-signup-requests-table"}
                    tableTitles={[
                      "S.No.",
                      "Full Name",
                      "Email Address",
                      "Date",
                      "Phone No.",
                      "Action",
                    ]}
                    dateFilter
                    showEntries
                    // sortBy
                    isLoading={isLoading}
                    isFetching={isFetching}
                    data={data?.data?.data?.docs}
                    totalPages={data?.data?.data?.totalPages}
                    totalDocs={data?.data?.data?.totalDocs}
                    setPage={setPage}
                    setSearchString={setSearchString}
                    status={status}
                    setStatus={setStatus}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                  // status_options={[
                  //   {
                  //     label: "Pending",
                  //     value: "pending",
                  //   },
                  //   {
                  //     label: "Approved",
                  //     value: "approved",
                  //   },
                  //   {
                  //     label: "Rejected",
                  //     value: "rejected",
                  //   },
                  // ]}
                  >
                    {!isFetching &&
                      data?.data?.data?.docs?.map((item, index) => (
                        <tr>
                          <td>{getSerialNumber(data?.data?.data, index)}</td>
                          <td>{item?.user?.firstName}</td>
                          <td>{item?.user?.email}</td>
                          <td>
                            {item?.createdAt &&
                              format_date(item?.createdAt, "MM/DD/YYYY")}
                          </td>
                          <td>{item?.user?.phone}</td>
                          <td>
                            <StatusHandler
                              key={`${item?._id}-status`}
                              view
                              // approval
                              item={item}
                              // isLoading={loadingStatus}
                              redirectUrl={`/mover-management/signup-requests/${item?._id}`}
                            // onClick={() => {
                            //   if (item.requestApprovalStatus === "pending") {
                            //     Confirmation(
                            //       `Are You Sure You Want To Approve The Signup Request ?`,
                            //       "Yes",
                            //       () => console.log("Status Changed")
                            //     );
                            //   } else {
                            //     Reason(
                            //       "Are you sure you want to reject the signup request ?",
                            //       "Yes",
                            //       (val) => console.log("Val", val)
                            //     );
                            //   }
                            // }}
                            />
                          </td>
                        </tr>
                      ))}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
