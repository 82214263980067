import Table from "../../Components/Table";
import StatusHandler from "../../Components/Table/StatusHandler";
import useFetchData from "../../Hooks/useFetchData";
import useTableControls from "../../Hooks/useTableControls";
import { getEarnings } from "../../Services/Earning";
import { format_date, getSerialNumber } from "../../Utils/helpers";

export default function Earnings() {
  // const sampleData = [
  //   {
  //     sn: "1",
  //     postedBy: "Abc User",
  //     mover: "Abc Mover",
  //     datePosted: "01/02/2022",
  //     cost: "000",
  //     moverCommission: "000",
  //     adminCommission: "000",
  //     jobStatus: "pending",
  //   },
  //   {
  //     sn: "2",
  //     postedBy: "Abc User",
  //     mover: "Abc Mover",
  //     datePosted: "01/02/2022",
  //     cost: "000",
  //     moverCommission: "000",
  //     adminCommission: "000",
  //     jobStatus: "rejected",
  //   },
  //   {
  //     sn: "3",
  //     postedBy: "Abc User",
  //     mover: "Abc Mover",
  //     datePosted: "01/02/2022",
  //     cost: "000",
  //     moverCommission: "000",
  //     adminCommission: "000",
  //     jobStatus: "completed",
  //   },
  //   {
  //     sn: "4",
  //     postedBy: "Abc User",
  //     mover: "Abc Mover",
  //     datePosted: "01/02/2022",
  //     cost: "000",
  //     moverCommission: "000",
  //     adminCommission: "000",
  //     jobStatus: "ongoing",
  //   },
  //   {
  //     sn: "5",
  //     postedBy: "Abc User",
  //     mover: "Abc Mover",
  //     datePosted: "01/02/2022",
  //     cost: "000",
  //     moverCommission: "000",
  //     adminCommission: "000",
  //     jobStatus: "pending",
  //   },
  // ];

  const {
    perPage,
    setPerPage,
    status,
    setStatus,
    search_string,
    setSearchString,
    from,
    setFrom,
    to,
    setTo,
  } = useTableControls();

  const {
    // INTERNAL EXPORTS
    setPage,
    // REACT QUERY EXPORTS
    isFetching,
    isLoading,
    data,
    refetch,
  } = useFetchData("earning-logs", getEarnings, [
    perPage,
    search_string,
    status,
    from,
    to,
  ]);
  // console.log("earning-logs Data: ", data?.data);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <>
                    <div className="row mb-4">
                      <div className="col-12">
                        <div className="back-title">
                          <div className="pageTitleInner">
                            <h1 className="pageTitle text-capitalize m-0">
                              Earnings
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-12">
                        <div className="d-xl-flex align-items-center flex-wrap">
                          <div className="dashCard2 d-flex justify-content-between align-items-center flex-grow-1 gap-5 min-h-200 mx-3 my-3 py-3 px-5">
                            <div className="totalContent mr-1">
                              <div className="totalInfoBox">
                                <p className="topicTitle">Total Earning</p>
                                <h3 className="totalHeading text-black">
                                  {/* $715.125 */}
                                  {data?.data?.data?.totalEarnings
                                    ? `$${data?.data?.data?.totalEarnings?.toFixed(
                                      2
                                    )}`
                                    : `$${0}`}
                                </h3>
                              </div>
                            </div>
                            <div className="totalPie2 flex-shrink-0 ">
                              <img
                                src="images/earning.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="dashCard2 d-flex justify-content-between align-items-center flex-grow-1 gap-5 min-h-200 mx-3 my-3 py-3 px-5">
                            <div className="totalContent mr-1">
                              <div className="totalInfoBox">
                                <p className="topicTitle">Week's Earning</p>
                                <h3 className="totalHeading text-black">
                                  {/* $915.125 */}
                                  {data?.data?.data?.weekEarnings
                                    ? `$${data?.data?.data?.weekEarnings?.toFixed(
                                      2
                                    )}`
                                    : `$${0}`}
                                </h3>
                              </div>
                            </div>
                            <div className="totalPie2 flex-shrink-0 ">
                              <img
                                src="images/earning.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="dashCard2 d-flex justify-content-between align-items-center flex-grow-1 gap-5 min-h-200 mx-3 my-3 py-3 px-5">
                            <div className="totalContent mr-1">
                              <div className="totalInfoBox">
                                <p className="topicTitle">Today's Earning</p>
                                <h3 className="totalHeading text-black">
                                  {/* $418.125 */}
                                  {data?.data?.data?.todayEarnings
                                    ? `$${data?.data?.data?.todayEarnings?.toFixed(
                                      2
                                    )}`
                                    : `$${0}`}
                                </h3>
                              </div>
                            </div>
                            <div className="totalPie2 flex-shrink-0 ">
                              <img
                                src="images/earning.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                  <Table
                    key={"earnings-table"}
                    tableTitles={[
                      "S.No.",
                      "Posted By",
                      "Muvrr",
                      "Date Posted",
                      "Cost",
                      "Admin Amount",
                      // "Mover Commission",
                      "Admin Commission",
                      "Job Status",
                      // "Action",
                    ]}
                    dateFilter
                    showEntries
                    // sortBy
                    isLoading={isLoading}
                    isFetching={isFetching}
                    data={data?.data?.data?.docs}
                    totalPages={data?.data?.data?.totalPages}
                    totalDocs={data?.data?.data?.totalDocs}
                    setPage={setPage}
                    setSearchString={setSearchString}
                    status={status}
                    setStatus={setStatus}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                  >
                    {!isFetching &&
                      data?.data?.data?.docs?.map((item, index) => (
                        <tr>
                          <td>{getSerialNumber(data?.data?.data, index)}</td>
                          <td>{item?.user?.firstName}</td>
                          <td>{item?.driver?.firstName}</td>
                          <td>
                            {item?.createdAt &&
                              format_date(item?.createdAt, "MM/DD/YYYY")}
                          </td>
                          <td>
                            {item?.bookingDetails?.price &&
                              `$${item?.bookingDetails?.price?.toFixed(2)}`}
                          </td>
                          {/* <td>${item.moverCommission}</td> */}
                          <td>
                            {item?.bookingDetails?.adminEarnedAmount &&
                              `$${item?.bookingDetails?.adminEarnedAmount?.toFixed(
                                2
                              )}`}
                          </td>
                          <td>
                            {item?.bookingDetails?.adminCommission &&
                              `${item?.bookingDetails?.adminCommission}%`}
                          </td>
                          <td>{item?.status}</td>
                          {/* <td>
                            <StatusHandler
                              key={`${item?._id}-status`}
                              view
                              redirectUrl={`/earnings/earning-details/${item?._id}`}
                            />
                          </td> */}
                        </tr>
                      ))}
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
