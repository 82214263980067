import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getFeedbackDetails } from "../../Services/Feedback";
import { format_date } from "../../Utils/helpers";

export default function FeedbackDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [feedback, setFeedback] = useState({});

  const { data, isLoading, refetch } = useQuery(["feedback_details", id], () =>
    getFeedbackDetails(id)
  );
  // console.log("feedback_details: ", data?.data);

  useEffect(() => {
    if (data?.data?.data?.docs?.length > 0)
      setFeedback(data?.data?.data?.docs?.[0]);
  }, [data?.data?.data?.docs]);

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-xl-6">
                      <div className="back-title">
                        <div className="pageTitleInner d-flex align-items-baseline">
                          <h1 className="pageTitle text-capitalize m-0 mt-1">
                            Feedbacks
                          </h1>
                          <span className="mx-2">
                            <button
                              type="button"
                              className="backLink mr-1"
                              onClick={() => navigate("/feedbacks")}
                            >
                              <i className="fas fa-chevron-left" />
                            </button>
                            Feedback Details
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row py-4 px-4 mb-3">
                    <div className="col-12">
                      <div className="row mb-4">
                        <div className="col-xxl-6">
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Full Name:
                            </label>
                            <p className="mainText">{feedback?.user?.firstName}</p>
                          </div>
                          {/* <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Type:
                            </label>
                            <p className="mainText">Carrier</p>
                          </div> */}
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Email Address:
                            </label>
                            <p className="mainText">{feedback?.email}</p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              User Type:
                            </label>
                            <p className="mainText">
                              {feedback?.user?.role ?? "-"}
                            </p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Subject:
                            </label>
                            <p className="mainText">{feedback?.subject}</p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Date Submitted:
                            </label>
                            <p className="mainText">
                              {feedback?.createdAt &&
                                format_date(feedback?.createdAt, "DD/MM/YYYY")}
                            </p>
                          </div>
                          <div className="mainDetail dashCard2 d-xl-flex align-items-baseline py-2 px-3 my-3">
                            <label
                              htmlFor=""
                              className="mainLabel mainLabel-lg"
                            >
                              Description:
                            </label>
                            <p className="mainText">{feedback?.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
