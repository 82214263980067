const Contactus = () => {
    return (
        <div className="container">
            <div className="contact-card border-0 p-4 p-xl-5 card">
                <div className="row">
                    <div className="col-lg-4 align-self-center">
                        <h4 className="mb-3 mb-xl-5">
                            Please let us know how we can improve your experience{" "}
                        </h4>
                        <div className="contact-info-block">
                            <i className="fa-solid fa-phone" />
                            <h6 className="fw-bold">Phone</h6>
                            <a href="tel:+17164744318">+17164744318</a>
                        </div>
                        <div className="single-line-dashed-separator" />
                        <div className="contact-info-block">
                            <i className="fa-regular fa-envelope" />
                            <h6 className="fw-bold">Email</h6>
                            <a title="" href="mailto:customer.service@muvrrs.com">
                                customer.service@muvrrs.com
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <form>
                            <div className="row">
                                <div className="mb-3 col-lg-6 col-12">
                                    <label className="ps-3 mb-2" htmlFor="name">
                                        Name<span className="text-danger">*</span>
                                    </label>
                                    <div className="inputField-wrap">
                                        <input
                                            id="name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter full name"
                                            required=""
                                            defaultValue=""
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 col-lg-6 col-12">
                                    <label className="ps-3 mb-2" htmlFor="email">
                                        Email<span className="text-danger">*</span>
                                    </label>
                                    <div className="inputField-wrap">
                                        <input
                                            id="email"
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter Email address"
                                            required=""
                                            defaultValue=""
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="ps-3 mb-2" htmlFor="subject">
                                        Subject<span className="text-danger">*</span>
                                    </label>
                                    <div className="inputField-wrap">
                                        <input
                                            id="subject"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Subject"
                                            required=""
                                            defaultValue=""
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="ps-3 mb-2" htmlFor="message">
                                        Message<span className="text-danger">*</span>
                                    </label>
                                    <div className="inputField-wrap">
                                        <textarea
                                            id="message"
                                            type="textarea"
                                            className="form-control"
                                            placeholder="Enter Message"
                                            required=""
                                            rows={6}
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary" type="button">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactus