import { useState } from "react";
import Pagination from "./Pagination";
import { useDebouncedEffect } from "../../Hooks/useDebouncedEffect";
import TableBodyHandler from "./TableBodyHandler";

export default function Table({
  children,
  showTableName,
  tableName,
  tableTitles,
  showEntries,
  sortBy,
  dateFilter,
  from,
  setFrom,
  to,
  setTo,
  perPage,
  setPerPage,
  status,
  setStatus,
  status_options,
  setSearchString,
  isLoading,
  isFetching,
  data,
  totalPages,
  totalDocs,
  setPage,
  nosearch
}) {
  const [internal_search, setInternalSearch] = useState("");

  useDebouncedEffect(
    () => setSearchString && setSearchString(internal_search),
    [internal_search],
    500
  );

  return (
    <>
      {/* <div className="card-content collapse show dashCard pt-5 px-5"> */}
      {showTableName && (
        <div className="row mb-4">
          <div className="col-12">
            <div className="back-title">
              <div className="pageTitleInner">
                <h1 className="pageTitle text-capitalize m-0">
                  {tableName}
                  {/* Users Management */}
                </h1>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row mb-3">
        <div className="col-12">
          <div className="dataTables_length text-start mb-4">
            <div className="user-listing-top dashCard2 py-4 px-4">
              <div className="d-xxl-flex align-items-end justify-content-between mb-xxl-3">
                <div className="userInput mb-3">
                  {dateFilter && (
                    <div className="d-md-flex align-items-center">
                      <label
                        htmlFor=""
                        className="dashLabel mb-3 mb-xxl-0 me-3"
                      >
                        Sort By Date
                      </label>
                      <div className="d-sm-flex">
                        <div className="mb-2 mb-xxl-0 me-sm-3">
                          <input
                            className="dashInput p-3"
                            type="date"
                            value={from}
                            onChange={({ target }) => setFrom(target.value)}
                          />
                        </div>
                        <div className="mb-2 mb-xxl-0">
                          <input
                            className="dashInput p-3"
                            type="date"
                            value={to}
                            onChange={({ target }) => setTo(target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {
                  !nosearch &&
                  <div className="userInput mb-2">
                    <div className="form-field mb-3">
                      <div className="d-xxl-flex align-items-center justify-content-end">
                        <div className="dataTables_filter">
                          <div className="dashSearchWrap">
                            {/* <form> */}
                            {/* <form action="" method="POST"> */}
                            <input
                              type="search"
                              id="search-inp"
                              className="dashInput search-input w-100"
                              placeholder="Search"
                              value={internal_search}
                              onChange={({ target }) =>
                                setInternalSearch(target.value)
                              }
                            />
                            <button className="search-icon">
                              {/* <button type="submit" className="search-icon"> */}
                              <i className="fas fa-search" />
                            </button>
                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="d-xxl-flex align-items-end flex-wrap justify-content-between mb-xxl-3">
                <div className="userInput mb-3">
                  {showEntries && (
                    <>
                      <label className="dashLabel d-inline-block mb-3 mb-xxl-0 me-3">
                        Show
                      </label>
                      <select
                        className="d-inline-block dashInput sm-dropdown"
                        onChange={({ target }) =>
                          setPerPage && setPerPage(target.value)
                        }
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <label className="dashLabel d-inline-block mb-3 mb-xxl-0 ms-3">
                        Entries
                      </label>
                    </>
                  )}
                </div>
                <div className="userInput mb-3">
                  {sortBy && (
                    <>
                      <label className="dashLabel d-inline-block mb-3 mb-xxl-0">
                        Filter By:
                      </label>
                      <select
                        className="d-inline-block dashInput"
                        value={status}
                        onChange={({ target }) => setStatus(target.value)}
                      >
                        <option value="">All</option>
                        {status_options && status_options?.length > 0 ? (
                          status_options?.map((status) => (
                            <option value={status?.value}>
                              {status?.label}
                            </option>
                          ))
                        ) : (
                          <>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                          </>
                        )}
                      </select>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="maain-tabble table-responsive">
            <table className="table table-bordered zero-configuration">
              <thead>
                <tr>
                  {tableTitles?.map((title) => (
                    <th>{title}</th>
                  ))}
                  {/* <th>S No.</th>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Date Registered</th>
                    <th>Status</th>
                    <th>Action</th> */}
                </tr>
              </thead>
              <TableBodyHandler
                data={data}
                length={tableTitles?.length}
                isLoading={isLoading}
                isFetching={isFetching}
              />
              <tbody>{children}</tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <Pagination
          // currentDocs={10}
          // totalDocs={100}
          // totalPages={4}
          // setPage={(val) => console.log("Value", val)}

          currentDocs={data?.length || data?.docs?.length}
          totalDocs={totalDocs}
          totalPages={totalPages}
          setPage={setPage}
        />
      </div>
      {/* </div> */}
    </>
  );
}
