import Gauge from "../../Components/Gauge";
import ProgressBar from "../../Components/ProgressBar";
import LineChart from "../../Components/LineChart";
import useFetchData from "../../Hooks/useFetchData";
import { getAllUsers } from "../../Services/User";
import { getDashboardStats } from "../../Services/Dashboard";
import { format_date, getSerialNumber } from "../../Utils/helpers";
import { useQuery } from "react-query";
import { useState } from "react";

export default function Dashboard() {
  const [year, setYear] = useState(new Date().getFullYear()?.toString());

  const currentYear = new Date().getFullYear();
  const minOffset = 0;
  const maxOffset = 15;
  const options = Array.from(
    { length: maxOffset - minOffset + 1 },
    (_, index) => {
      const yearData = currentYear - index;
      return (
        <option key={yearData} value={yearData}>
          {yearData}
        </option>
      );
    }
  );

  const {
    // INTERNAL EXPORTS
    setPage,
    // REACT QUERY EXPORTS
    isFetching: userFetching,
    isLoading: userLoading,
    data: userData,
    refetch,
  } = useFetchData("new_user_logs", getAllUsers, [5, "user"]);

  const { isLoading, data } = useQuery(["getDashboardStats", year], () =>
    getDashboardStats(year)
  );
  // console.log("getDashboardStats: ", data?.data);

  function getLoadingWeightPercent(loadPercent = 0) {
    loadPercent = loadPercent?.toFixed(2);
    loadPercent = (loadPercent && loadPercent / 100)?.toFixed(2);
    return loadPercent;
  }

  return (
    <div className="app-content content dashboard">
      <div className="content-wrapper">
        <div className="content-body">
          {/* Basic form layout section start */}
          <section id="configuration">
            <div className="row">
              <div className="col-12">
                <div className="card-content collapse show dashCard pt-5 px-5">
                  <div className="row mb-4">
                    <div className="col-12">
                      <h1 className="pageTitle text-capitalize m-0">
                        Dashboard
                      </h1>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="d-xl-flex align-items-center justify-content-between flex-wrap">
                        <div className="align-items-center cardShadow d-flex dashCard flex-column-reverse flex-grow-1 flex-md-row gap-5 justify-content-between min-h-200 my-3 px-5 py-3">
                          <div className="totalContent mr-1">
                            <h3 className="totalHeading mb-4">Fleet Status</h3>
                            <div className="totalInfoBox">
                              <div className="totalInfo d-flex align-items-baseline gap-2">
                                <p className="totalLabel">Total Fleet:</p>
                                <p className="totalValue">
                                  {data?.data?.data?.fleet?.totalDrivers ?? 0}
                                </p>
                              </div>
                              <div className="totalInfo d-flex align-items-baseline gap-2">
                                <p className="totalLabel">On The Move:</p>
                                <p className="totalValue">
                                  {data?.data?.data?.fleet?.ongoingShipments ??
                                    0}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="totalPie flex-shrink-0 text-center">
                            <img
                              src="images/totalImage1.png"
                              alt=""
                              className="img-fluid"
                            />
                            <p className="my-2">Fleet Efficiency</p>
                          </div> */}
                        </div>
                        <div className="align-items-center cardShadow d-flex dashCard flex-column-reverse flex-grow-1 flex-md-row gap-5 justify-content-between min-h-200 my-3 px-5 py-3">
                          <div className="totalContent mr-1">
                            {/* <div className="totalInfoBox mb-4">
                              <h3 className="totalHeading">20 min</h3>
                              <p className="totalValue">Avg. Loading Time</p>
                            </div> */}
                            <div className="totalInfoBox mb-4">
                              <h3 className="totalHeading">
                                {data?.data?.data?.ridesStats
                                  ?.averageLoadingWeight
                                  ? `${data?.data?.data?.ridesStats?.averageLoadingWeight?.toFixed(
                                      2
                                    )} lbs`
                                  : `${0} lbs`}
                              </h3>
                              <p className="totalValue">Avg. Loading Weight</p>
                            </div>
                          </div>
                          <div className="totalPie flex-shrink-0 text-center">
                            {/* <img
                              src="images/totalImage2.png"
                              alt=""
                              className="img-fluid"
                            /> */}
                            <Gauge
                              key={"gauge-dashboard"}
                              percent={getLoadingWeightPercent(
                                data?.data?.data?.ridesStats
                                  ?.currentMonthLoadPercent
                              )}
                            />
                          </div>
                        </div>
                        <div className="align-items-center cardShadow d-flex dashCard flex-column-reverse flex-grow-1 flex-md-row gap-5 justify-content-between min-h-200 my-3 px-5 py-3">
                          <div className="totalContent mr-1">
                            <h3 className="totalHeading mb-4">User Status</h3>
                            <div className="totalInfoBox">
                              <div className="totalInfo d-flex align-items-baseline gap-2 mb-3">
                                <p className="totalLabel">Active:</p>
                                <p className="totalValue">
                                  {data?.data?.data?.userCount?.activeUsers ??
                                    0}
                                </p>
                              </div>
                              <div className="totalInfo d-flex align-items-baseline gap-2">
                                <p className="totalLabel">Inactive:</p>
                                <p className="totalValue">
                                  {data?.data?.data?.userCount?.inactiveUsers ??
                                    0}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="totalContent mr-1">
                            <h3 className="totalHeading mb-4">
                              Delivery Status
                            </h3>
                            <div className="totalInfoBox">
                              <div className="totalInfo d-flex align-items-baseline gap-2 mb-3">
                                <p className="totalLabel">Within Time Limit:</p>
                                <p className="totalValue">400</p>
                              </div>
                              <div className="totalInfo d-flex align-items-baseline gap-2">
                                <p className="totalLabel">
                                  Out of the Time Limit:
                                </p>
                                <p className="totalValue">75</p>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="totalPie flex-shrink-0 text-center">
                            <img
                              src="images/totalImage3.png"
                              alt=""
                              className="img-fluid"
                            />
                            <div style={{ width: "120px" }}>
                              <ProgressBar key={"progress-bar"} percent={50} />
                            </div>
                            <p className="my-2">Within Time Limit</p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="d-xl-flex align-items-center flex-wrap">
                        <div className="dashCard cardShadow d-flex justify-content-between align-items-center gap-5 min-h-200 my-3 py-3 px-5">
                          <div className="totalContent mr-1">
                            <div className="totalInfoBox">
                              <p className="totalLabel">Revenue</p>
                              <h3 className="totalHeading mb-4">
                                {data?.data?.data?.adminEarnings?.[0]?.revenue
                                  ? `$${data?.data?.data?.adminEarnings?.[0]?.revenue?.toFixed(
                                      2
                                    )}`
                                  : `$${0}`}
                              </h3>
                            </div>
                          </div>
                          {/* <div className="totalPie2 flex-shrink-0 ">
                            <img
                              src="images/totalImage3.png"
                              alt=""
                              className="img-fluid"
                            />
                            <div style={{ width: "90px" }}>
                              <ProgressBar key={"progress-bar"} percent={50} />
                            </div>
                          </div> */}
                        </div>
                        {/* <div className="dashCard cardShadow d-flex justify-content-between align-items-center gap-5 min-h-200 my-3 py-3 px-5">
                          <div className="totalContent mr-1">
                            <div className="totalInfoBox">
                              <p className="totalLabel">Costs</p>
                              <h3 className="totalHeading mb-4">$340.985</h3>
                            </div>
                          </div>
                          <div className="totalPie2 flex-shrink-0 ">
                            <img
                              src="images/totalImage3.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div> */}
                        <div className="dashCard cardShadow d-flex justify-content-between align-items-center gap-5 min-h-200 my-3 py-3 px-5">
                          <div className="totalContent mr-1">
                            <div className="totalInfoBox">
                              <p className="totalLabel">Profit</p>
                              <h3 className="totalHeading mb-4">
                                {data?.data?.data?.adminEarnings?.[0]?.profit
                                  ? `$${data?.data?.data?.adminEarnings?.[0]?.profit?.toFixed(
                                      2
                                    )}`
                                  : `$${0}`}
                              </h3>
                            </div>
                          </div>
                          {/* <div className="totalPie2 flex-shrink-0 ">
                            <img
                              src="images/totalImage3.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div> */}
                        </div>
                        <div className="dashCard cardShadow d-flex justify-content-between align-items-center gap-5 min-h-200 my-3 py-3 px-5">
                          <div className="totalContent mr-1">
                            <div className="totalInfoBox">
                              <p className="totalLabel">Shipments</p>
                              <h3 className="totalHeading mb-4">
                                {data?.data?.data?.shipments
                                  ?.completedShipments ?? 0}
                              </h3>
                            </div>
                          </div>
                          {/* <div className="totalPie2 flex-shrink-0 ">
                            <img
                              src="images/totalImage3.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div> */}
                        </div>
                        {/* <div className="dashCard cardShadow d-flex justify-content-between align-items-center gap-5 min-h-200 my-3 py-3 px-5">
                          <div className="totalContent mr-1">
                            <div className="totalInfoBox">
                              <p className="totalLabel">Avg. Delivery Time</p>
                              <h3 className="totalHeading mb-4">75 H</h3>
                            </div>
                          </div>
                          <div className="totalPie2 flex-shrink-0 ">
                            <img
                              src="images/totalImage3.png"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mb-4">
                    <div className="col-xxl-6">
                      <div className="d-flex">
                        <div className="progressData flex-grow-1">
                          <div className="d-flex align-items-end justify-content-between">
                            <div className="w-100">
                              <label htmlFor="" className="progressTitle">
                                States
                              </label>
                              <select name="" id="" className="dashInput">
                                <option value={1} selected="">
                                  Last 14 Days
                                </option>
                                <option value={2}>Last 28 Days</option>
                                <option value={3}>Last 72 Days</option>
                              </select>
                            </div>
                            <div className="w-100 text-end">
                              <p className="m-0">
                                <span className="totalHeading">911</span>
                                Shipment Requests
                              </p>
                            </div>
                          </div>
                          <div className="progressBars my-5">
                            <div className="progressBox">
                              <div className="progressDetails d-flex align-items-end justify-content-between">
                                <h3 className="progressTitle">Abc State</h3>
                                <h3 className="totalHeading">1,896</h3>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "100%" }}
                                  aria-valuenow={100}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                            <div className="progressBox">
                              <div className="progressDetails d-flex align-items-end justify-content-between">
                                <h3 className="progressTitle">Abc State</h3>
                                <h3 className="totalHeading">568</h3>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "60%" }}
                                  aria-valuenow={60}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                            <div className="progressBox">
                              <div className="progressDetails d-flex align-items-end justify-content-between">
                                <h3 className="progressTitle">Abc State</h3>
                                <h3 className="totalHeading">987</h3>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "75%" }}
                                  aria-valuenow={75}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                            <div className="progressBox">
                              <div className="progressDetails d-flex align-items-end justify-content-between">
                                <h3 className="progressTitle">Abc State</h3>
                                <h3 className="totalHeading">2564</h3>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "90%" }}
                                  aria-valuenow={90}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                            <div className="progressBox">
                              <div className="progressDetails d-flex align-items-end justify-content-between">
                                <h3 className="progressTitle">Abc State</h3>
                                <h3 className="totalHeading">458</h3>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "60%" }}
                                  aria-valuenow={60}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                            <div className="progressBox">
                              <div className="progressDetails d-flex align-items-end justify-content-between">
                                <h3 className="progressTitle">Abc State</h3>
                                <h3 className="totalHeading">256</h3>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "100%" }}
                                  aria-valuenow={100}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="progressCounter mx-3">
                          <button type="button" className="notBtn">
                            +
                          </button>
                          <hr className="my-0" />
                          <button type="button" className="notBtn">
                            -
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xxl-6">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50470.02846874147!2d-122.47261937941492!3d37.75776267828957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2s!4v1649050746012!5m2!1sen!2s"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    </div>
                  </div> */}
                  <div className="row mb-5">
                    <div className="col-12">
                      <div className="d-lg-flex align-items-center justify-content-between">
                        <h2 className="progressTitle">Total Muvrrs</h2>
                        <div className="form-field text-right">
                          <select
                            className="dashInput"
                            name=""
                            id=""
                            required=""
                            value={year}
                            onChange={({ target }) => setYear(target.value)}
                          >
                            {options}
                            {/* <option value="" selected="" hidden="" disabled="">
                              Year
                            </option> */}
                          </select>
                        </div>
                      </div>
                      <div className="w-100">
                        {/* <img
                          src="images/graph.png"
                          alt=""
                          className="img-fluid w-100"
                        /> */}
                        <LineChart
                          key={"line-chart"}
                          data={data?.data?.data?.stats?.data}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <h2 className="sectionTitle my-2">New Users</h2>
                      <div className="maain-tabble table-responsive">
                        <table className="table table-bordered zero-configuration">
                          <thead>
                            <tr>
                              <th>S No.</th>
                              <th>User Name</th>
                              <th>Date Registered</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!userLoading && !userFetching ? (
                              userData?.data?.data?.docs?.length > 0 ? (
                                userData?.data?.data?.docs?.map(
                                  (item, index) => (
                                    <tr>
                                      <td>
                                        {getSerialNumber(
                                          userData?.data?.data,
                                          index
                                        )}
                                      </td>
                                      <td>{item.firstName}</td>
                                      <td>
                                        {item?.createdAt
                                          ? format_date(
                                              item?.createdAt,
                                              "DD/MM/YYYY"
                                            )
                                          : "-"}
                                      </td>
                                      <td>
                                        {item.active ? "Active" : "Inactive"}
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <td colSpan={4} style={{ textAlign: "center" }}>
                                  No Records Found
                                </td>
                              )
                            ) : (
                              <td colSpan={4} style={{ textAlign: "center" }}>
                                <i
                                  className="fas fa-circle-notch fa-spin"
                                  style={{ fontSize: 42 }}
                                />
                              </td>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
